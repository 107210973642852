import React, { useEffect, useState } from 'react';

import BasicLayout from "layouts/authentication/components/BasicLayout";
import EmployerRegistration from "./employer/index.js";
import JobSeekerRegistration from "./seeker/index.js";
import { Grid } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import MDBox from 'components/MDBox/index.js';
import MDTypography from 'components/MDTypography/index.js';
function Cover() {
  const [registrationType, setRegistrationType] = useState('employer');
  const { slug } = useParams();

  const handleRegistrationType = (type) => {
    setRegistrationType(type);
  };

  return (
    <BasicLayout>
      <div className='signup-wrapper'>
        <div className='container'>
          <div className='row mt-5 mb-5 pt-5 d-flex align-items-center justify-content-center'>
            <div className='col-md-6 signup-box mb-3'>
              <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-md-12 left-content p-5'>
                  <h4>Signup</h4>
                  <hr></hr>
                  <p><strong>Get register with one of the best International Professional Community. Explore
                    Opportunity, Collaborate, Hire Talents</strong></p>
                  <Link to={'/sign-up-as-organization'}>
                    <div className='card mt-3'>
                      <div class="card-body">
                      
                        <h5 class="card-title d-flex align-items-center"><span class="material-symbols-outlined"> corporate_fare </span> &nbsp; Organization</h5>
                        <h6 class="card-subtitle mb-2 mt-3">I’m here to hire Talents</h6>
                        <p class="card-text">Explore, Showcase Portfolio, Collaborate, Hire Talents. Globally</p>
                      </div>
                    </div>
                  </Link>

                  <Link to={'/sign-up-as-professional'}>
                    <div className='card mt-4'>
                      <div class="card-body">
                      
                        <h5 class="card-title d-flex align-items-center"><span class="material-symbols-outlined"> person </span> &nbsp; Professionals</h5>
                        <h6 class="card-subtitle mb-2 mt-3">I’m here to explore Jobs</h6>
                        <p class="card-text">Explore Opportunities, International projects information and get hired.</p>
                      </div>
                    </div>
                  </Link>
                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Already have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign In
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </div>
                {/* <div className='col-md-6 mb-3'>
                  {registrationType === 'employer' ?
                  <EmployerRegistration handleRegistrationType={handleRegistrationType} />
                  :
                  <JobSeekerRegistration handleRegistrationType={handleRegistrationType} />
                  }
                </div> */}
              </div>
            </div>

          </div>

        </div>

      </div>
      {/* <Grid container  justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={9} md={6} lg={4} xl={6}>
          <div className="container d-flex flex-column w-50 mx-auto">
            
          </div>
        </Grid>
      </Grid> */}

    </BasicLayout>


  );
}

export default Cover;
