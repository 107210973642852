import React, { useEffect, useState } from 'react';
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import { Link, useNavigate, useParams } from 'react-router-dom';
import ShowShareModal from 'layouts/projects/ShowShareModal';
import defaultImage from "../../assets/images/news-default.png"
import { format, parseISO } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import axios from 'axios';


const View = () => {
  const [data, setData] = useState([]);
  const [recomNews, setRecomNews] = useState([]);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const navigate = useNavigate()
  const { slug } = useParams();
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const node = process.env.REACT_APP_NODE_ENV || 'local';
  const { token, user } = useSelector(selectAuth);

  const [selectedReportReason, setSelectedReportReason] = useState('');

  const reportOptions = [
    "Fake or misleading information",
    "Scam, fraud, or impersonation",
    "Nudity or sexual content",
    "Bullying or harassment",
    "Hate speech or offensive language",
    "Graphic violence or abuse",
    "Promotion of illegal or restricted items",
    "Infringement of privacy",
    "Spam or irrelevant content"
  ];



  const convertDate = (date) => {
    const data = date;
    const formattedDeadline = data ? format(parseISO(data), 'eeee, MMMM d, yyyy') : '';
    return formattedDeadline;
  }
  const copyURLToClipboard = () => {
    const currentURL = window.location.href;
    navigator.clipboard.writeText(currentURL);
    setShowCopyModal(false)
    toast.success('Link copied to clipboard!');
  };

  const handleReport = async (reason) => {
    try {
      const isConfirmed = window.confirm(`Are you sure you want to report this news as ${reason}?`);
      if (!isConfirmed) return;

      const formData = new FormData();
      formData.append('type', reason);
      
      const response = await axios.post(`${apiUrl}api/news/report-news/${data.id}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        toast.success('Report submitted successfully.');
        navigate(-1);
      } else {
        toast.error('Failed to submit report.');
      }
    } catch (error) {
      console.error('Error reporting news:', error);
      toast.error('Error reporting news.');
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}api/news/view/${slug}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      console.log('result', result);
      setData(result?.news);

      setRecomNews(result?.recomendedList);



    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();

  }, [slug]);
  return (
    <BasicLayout>
      <ToastContainer />
      <ShowShareModal
        show={showCopyModal}
        handleClose={() => setShowCopyModal(false)}
        copyURLToClipboard={copyURLToClipboard}
      />
      <div className="inner-banner">
        <div className="container d-flex justify-content-between align-items-center">
          <h2 >{data?.title}</h2>
          <button className='btn btn-dark' onClick={() => navigate(-1)}>Back</button>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-md-9">
            <div className="text-warning"><strong>{data?.sectors?.join('|| ')}</strong> </div>
            <h1 className="my-4">{data?.title}</h1>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="text-muted m-0">
                By {data?.user?.name} || {convertDate(data?.created_at)} || {data?.location}
              </p>
              <div className="d-flex gap-2">
                <button className="btn btn-outline-primary d-flex align-items-center gap-2" onClick={() => setShowCopyModal(true)}>
                  <span>Share</span>
                  <span className="material-symbols-outlined" style={{ fontSize: '20px' }}>
                    share
                  </span>
                </button>

                <div class="dropdodropupwn">
                  <button class="btn btn-outline-danger d-flex align-items-center gap-2 dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>Report</span>
                    <span className="material-symbols-outlined" style={{ fontSize: '20px' }}>
                      report
                    </span>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                    {reportOptions.map((option, index) => (
                      <button
                        key={index}
                        className="dropdown-item"
                        onClick={() => handleReport(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>

                {/* <button className="btn btn-outline-danger d-flex align-items-center gap-2">
                  <span>Report</span>
                  <span className="material-symbols-outlined" style={{ fontSize: '20px' }}>
                    report
                  </span>
                </button> */}
              </div>
            </div>
            <div className='mb-4 mt-3'>
              <img
                src={
                  data?.image
                    ? `${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data?.image}`
                    : defaultImage
                }
                className="img-fluid rounded w-full"
                alt={data?.title}
                style={{
                  maxHeight: '400px',
                  width: '100%',
                  objectFit: 'cover'
                }}
              />

            </div>

            <div className='article-content' dangerouslySetInnerHTML={{
              __html: data?.content
            }}>

            </div>
          </div>
          <div className='col-md-3'>
            <div className="bg-white p-4 rounded shadow-md border border-2">
              <h2 className="emphasized">Releated News</h2>
              <div className="">
                {recomNews.map((news, index) => (
                  <div key={news?.id} className="releated-news flex gap-3 mb-3">
                    <span className="number">{index + 1} </span>
                    <Link to={`/news-feed/${news?.slug}`} className="text-gray-700 hover:text-blue-600 transition-colors duration-200 text-sm">
                      {news?.title}
                    </Link>

                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

      </div>
    </BasicLayout>

  )
};

export default View;