import BasicLayout from 'layouts/authentication/components/BasicLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, CircularProgress, Icon, Autocomplete, TextField } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/authSlice';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';
import ShowApplyModal from 'layouts/projects/ShowApplyModal';
import CustomLoader from "components/CustomLoader";

const AppliedJobs = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [showFullFeatures, setShowFullFeatures] = useState(false);
    const { token, user, isNewUser } = useSelector(selectAuth);
    const [companyName, setSelectedCompanyName] = useState('');
    const [projectId, setSelectedProjectId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [positions, setPositions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('Applied');
    const statuses = ['Applied', 'Saved', 'Pending', 'In-review', 'Shortlisted', 'Rejected'];

    const navigate = useNavigate()
    const dispatch = useDispatch();

    function createSlug(text) {
        // Replace all spaces with hyphens and convert to lowercase
        return text.trim().toLowerCase().replace(/\s+/g, '-');
    }
    const toggleFeatures = () => {
        setShowFullFeatures(!showFullFeatures);
    };
    const handleApply = (name, id, positionName) => {
        setSelectedCompanyName(name)
        setSelectedProjectId(id)
        setShowModal(true)
        setPositions(positionName)
    }
    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : '';
        return formattedDeadline;
    }


    const fetchAppliedJobs = async (status) => {
        try {
            // setLoading(true);
            // Build query parameters for filtering
            const queryParams = new URLSearchParams();
            console.log("currentPage", currentPage)
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);
            queryParams.append('status', status);
            const response = await fetch(`${apiUrl}api/applied-jobs/${user?.user?.id}?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                console.log(result)
                setLoading(false);
                setData(result?.data?.data || []);
                setTotalPages(result.data?.last_page || 1);
                setCurrentPage(result.data?.current_page || 1); // Set total pages
                console.log(result?.data);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    

    // Update selected status and fetch data accordingly
    const handleStatusChange = (status) => {
        setSelectedStatus(status);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };



    useEffect(() => {
        fetchAppliedJobs(selectedStatus); // Fetch data initially with filtering
    }, [token, currentPage, selectedStatus]);

    return (
        <BasicLayout>
            <ToastContainer />
            {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
            <ShowApplyModal
                show={showModal}
                type='job'
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                companyName={companyName}
                projectId={projectId}
                positions={positions}
                handleDataFetch={fetchAppliedJobs}
            />
            <Grid container justifyContent="center" alignItems="center" className={showModal ? 'blurred-background' : ''}>
                <Grid item xs={12} sm={9} md={9} lg={8} xl={6}>
                    <div className="container mt-5 p-5">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card bg-secondary text-white p-5'>
                                    <div className='card-title text-center'>
                                        <h3>Applied Jobs</h3>
                                    </div>
                                    <div className='row mt-3'>
                                        {statuses.map((status, index) => (
                                            <div className="col-md-2" key={index}>
                                                <button
                                                    type="button"
                                                    className={`btn  ${selectedStatus === status ? 'btn-warning text-white' : 'btn-light'}`}
                                                    onClick={() => handleStatusChange(status)}
                                                >
                                                    {status}
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {data && data.length > 0 ? (
                                <>
                                {data[0]?.pivot ?
                                <>
                                {data?.map((job, index) => (
                                <>
                                    <div className="col-md-12 py-3" key={job?.id}>
                                        <div className="card">
                                            <div className="card-body">

                                                <div className="row align-items-center">
                                                    <div className="col-md-2">
                                                        <div className="rounded-circle d-inline-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px', backgroundColor: job?.project?.company?.logo ? '#000' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                                            {job?.company?.logo ? (
                                                                <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${job?.company?.logo}`} alt="Profile picture" className="img-fluid rounded-circle" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                            ) : (
                                                                <span className="text-uppercase"><strong>{job?.company?.company_name?.charAt(0)}</strong></span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        {job?.title && (
                                                            <Link to={`/job/${job?.slug}`}>
                                                                <h5 className="card-title text-primary"><strong>{job?.title}</strong></h5>
                                                            </Link>
                                                        )}

                                                        {token && (
                                                            <h6>{job?.company?.company_name}</h6>
                                                        )}

                                                    </div>

                                                </div>
                                                <div className='my-3 d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <i className="material-icons fs-6" title="Posted on">schedule</i>
                                                        <span className="fs-6 text-muted"> Posted on  {convertDate(job?.created_at)}</span>
                                                    </div>

                                                    <div className='px-3'>
                                                        <i className="material-icons fs-6" title={job?.job_opportunity?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                        >info</i>
                                                        <span className="fs-6 text-muted"> {job?.stage}</span>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className='mx-2' style={{ background: '#fafafa' }}>
                                                    <div className='row mb-3'>
                                                        <div className="col-md-4 pt-2 ">
                                                            <h6><strong>Sector</strong></h6>
                                                            <h6 className="text-primary">{job?.sector}</h6>
                                                        </div>
                                                        <div className="col-md-4 pt-2 ">
                                                            <h6><strong>Sub Sector</strong></h6>
                                                            <h6 className="text-primary">{job?.sub_sector}</h6>
                                                        </div>
                                                        <div className="col-md-4 pt-2 float-end">
                                                            <h6><strong>Location</strong></h6>
                                                            <h6 className="text-primary">{job?.location}</h6>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>

                            ))}
                                </>
                                :

                                <>
                                {data?.map((application, index) => (
                                <>
                                    <div className="col-md-12 py-3" key={application?.id}>
                                        <div className="card">
                                            <div className="card-body">

                                                <div className="row align-items-center">
                                                    <div className="col-md-2">
                                                        <div className="rounded-circle d-inline-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px', backgroundColor: application?.project?.company?.logo ? '#000' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                                            {application?.job_opportunity?.company?.logo ? (
                                                                <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${application?.job_opportunity?.company?.logo}`} alt="Profile picture" className="img-fluid rounded-circle" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                            ) : (
                                                                <span className="text-uppercase"><strong>{application?.job_opportunity?.company?.company_name?.charAt(0)}</strong></span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        {application?.position && (
                                                            <Link to={`/job/${application?.job_opportunity?.slug}`}>
                                                                <h5 className="card-title text-primary"><strong>{application?.position}</strong></h5>
                                                            </Link>
                                                        )}

                                                        {token && (
                                                            <h6>{application?.job_opportunity?.company?.company_name}</h6>
                                                        )}

                                                    </div>

                                                </div>
                                                <div className='my-3 d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <i className="material-icons fs-6" title="Posted on">schedule</i>
                                                        <span className="fs-6 text-muted"> Posted on  {convertDate(application?.job_opportunity?.created_at)}</span>
                                                    </div>
                                                    {convertDate(application?.created_at) && (
                                                        <div>
                                                            <i className="material-icons fs-6" title="Closing on">schedule</i>
                                                            <span className="fs-6 text-muted"> Applied on  {convertDate(application?.created_at)}</span>
                                                        </div>
                                                    )}

                                                    <div className='px-3'>
                                                        <i className="material-icons fs-6" title={application?.job_opportunity?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                        >info</i>
                                                        <span className="fs-6 text-muted"> {application?.job_opportunity?.stage}</span>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className='mx-2' style={{ background: '#fafafa' }}>
                                                    <div className='row mb-3'>
                                                        <div className="col-md-4 pt-2 ">
                                                            <h6><strong>Sector</strong></h6>
                                                            <h6 className="text-primary">{application?.job_opportunity?.sector}</h6>
                                                        </div>
                                                        <div className="col-md-4 pt-2 ">
                                                            <h6><strong>Sub Sector</strong></h6>
                                                            <h6 className="text-primary">{application?.job_opportunity?.sub_sector}</h6>
                                                        </div>
                                                        <div className="col-md-4 pt-2 float-end">
                                                            <h6><strong>Location</strong></h6>
                                                            <h6 className="text-primary">{application?.job_opportunity?.location}</h6>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>

                            ))}
                            </>
                            }
                            </>
                            )
                            :
                            <h2 className='text-center mt-5'>No Records found</h2>
                        }
                        </div>
                    </div>

                </Grid>
            </Grid>
            {/* Pagination */}
            {totalPages > 1 && (
                <MDPagination size="medium">
                    {currentPage > 1 && (
                        <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                            <Icon>keyboard_arrow_left</Icon>
                        </MDPagination>
                    )}
                    {Array.from({ length: totalPages }, (_, i) => (
                        <MDPagination item
                            key={i + 1}
                            active={currentPage === i + 1}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </MDPagination>
                    ))}
                    {currentPage < totalPages && (
                        <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                            <Icon>keyboard_arrow_right</Icon>
                        </MDPagination>
                    )}
                </MDPagination>
            )}
        </BasicLayout>

    );
};

export default AppliedJobs;
