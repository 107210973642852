import React, { useEffect, useState } from 'react';
import Footer from "examples/Footer";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDButton from 'components/MDButton';
import { Icon } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';

const Index = () => {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { token, user } = useSelector(selectAuth);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      if (token) {
        const response = await fetch(`${apiUrl}api/news/all-news`, {
          method: 'GET', // Adjust the HTTP method as needed
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        console.log(result)
        setData(result || []);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleApprove = async (newsId, message) => {
    // Show a confirmation dialog
    setShowAlert(false)
    const isConfirmed = window.confirm(message === 'publish' ? 'Are you sure you want to publish this news?' : 'Are you sure you want to draft this news?');

    if (isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}api/news/update-status/${newsId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();

        if (response.ok) {
          fetchData();
          setShowAlert(true);
          console.log("result", result)
          toast.success(result?.message);


        } else {
          // Handle error, e.g., show an error message
          console.error('Failed to approve news');
        }
      } catch (error) {
        // Handle network error
        console.error('Network error:', error);
      }
    }
  };

  const handleDelete = async (newsId) => {
    const confirmed = window.confirm('Are you sure you want to delete this news?');
    if (!confirmed) return; // If user cancels, do nothing
    try {
      const response = await fetch(`${apiUrl}api/news/destroy/${newsId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        toast.success('News deleted successfully!');
        fetchData();
      } else {
        setShowAlert(true);
        setError(result.message || 'Failed to delete news');
      }
    } catch (error) {
      console.error('Error deleting news:', error);
      setShowAlert(true);
      setError('Failed to delete news. Please try again later.');
    }
  }
  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="d-flex mx-4 mt-2">
                    <strong>News</strong>
                  </h3>
                  <DataTable
                    table={{
                      columns: [
                        { Header: "title", accessor: "title", width: "20%" },
                        { Header: "sector", accessor: "sector", width: "20%" },
                        { Header: "location", accessor: "location", width: "20%" },
                        { Header: "Status", accessor: "status", width: "12%" },
                        { Header: "Action", accessor: "action", width: "20%" },
                      ],
                      rows: data.map((news) => ({
                        title: news?.title || '',
                        sector: news?.sector || '',
                        location: news?.location || '',
                        status: news.status === 0 ? 'Inactive' : 'Active',
                        action:
                          <>
                            
                            {/* <MDButton variant="gradient" color="secondary" className="mx-2" onClick={() => navigate(`/news/${news?.slug}/edit`)} title="Edit">
                              <Icon>edit</Icon>&nbsp;
                            </MDButton> */}

                            {news?.status === 0 ? (
                              <MDButton variant="gradient" color="success" title='publish' onClick={() => handleApprove(news?.id, 'publish')} className="mx-2">
                                <Icon>check</Icon>&nbsp;
                              </MDButton>
                            ) : (
                                <MDButton variant="gradient" color="warning" title='draft' onClick={() => handleApprove(news?.id, 'Draft')} className="mx-2">
                                <Icon>close</Icon>&nbsp;
                              </MDButton>
                            )}

                            <MDButton variant="gradient" color="error" onClick={() => handleDelete(news.id)}>
                              <Icon>delete</Icon>&nbsp;
                            </MDButton>
                          </>
                      })),
                    }}
                  />
                </div>
              </div>
            </div>

      <Footer />
    </DashboardLayout>
  );
};

export default Index;
