import BasicLayout from 'layouts/authentication/components/BasicLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, CircularProgress, Icon, Autocomplete, TextField } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/authSlice';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';
import ShowApplyModal from 'layouts/projects/ShowApplyModal';
import { useLocation } from 'react-router-dom';
import CustomLoader from 'components/CustomLoader'

const Index = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [showFullFeatures, setShowFullFeatures] = useState(false);
    const { token, user, isNewUser } = useSelector(selectAuth);
    const [companyName, setSelectedCompanyName] = useState('');
    const [projectId, setSelectedProjectId] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedStage, setSelectedStage] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [searchkeyWord, setSearchKeyword] = useState('');
    const [positions, setPositions] = useState([]);
    const location = useLocation();
    const [savedJobs, setSavedJobs] = useState([]);

    const navigate = useNavigate()
    const dispatch = useDispatch();

    function createSlug(text) {
        // Replace all spaces with hyphens and convert to lowercase
        return text.trim().toLowerCase().replace(/\s+/g, '-');
    }
    const toggleFeatures = () => {
        setShowFullFeatures(!showFullFeatures);
    };
    const handleApply = (name, id, positionName) => {
        setSelectedCompanyName(name)
        setSelectedProjectId(id)
        setShowModal(true)
        setPositions(positionName)
    }
    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : '';
        return formattedDeadline;
    }

    // Handle selection of a sector
    const handleSectorChange = (event) => {
        const sectorName = event.target.value;
        setSelectedSector(sectorName);
    };

    const handleStageChange = (event) => {
        const value = event.target.value;
        setSelectedStage(value);
    };

    const handleDateChange = (event) => {
        const value = event.target.value;
        setSelectedDate(value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value); // Added handler for search term change
    };

    const fetchFilteredProjects = async () => {
        try {
            setLoading(true);
            const searchParams = new URLSearchParams(location.search);
            console.log("searchParams", searchParams?.size);

            let response;

            if (searchParams?.size > 1) {
                console.log("called")
                const searchData = Object.fromEntries(searchParams.entries());
                const queryParams = new URLSearchParams(searchData).toString();

                response = await fetch(`${apiUrl}api/jobs-filter?${queryParams}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
            } else {
                console.log("called2")

                // Build query parameters for filtering
                const queryParams = new URLSearchParams();
                if (selectedLocations.length > 0) {
                    queryParams.append('locations', selectedLocations.join(','));
                }

                if (selectedSector) {
                    queryParams.append('sector', selectedSector);
                }

                if (selectedStage) {
                    queryParams.append('stage', selectedStage);
                }

                if (selectedDate) {
                    queryParams.append('posted_date', selectedDate);
                }

                if (searchTerm) {
                    queryParams.append('search', searchTerm); // Added project name filter
                }
                queryParams.append('page', currentPage);
                queryParams.append('items_per_page', itemsPerPage);

                response = await fetch(`${apiUrl}api/jobs?${queryParams.toString()}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
            }

            const result = await response.json();
            if (result) {
                console.log('data', result?.data?.data);
                setSearchKeyword(result?.searchkeyWord || ''); // Set search keyword
                setData(result?.data?.data || []); // Set the data
                setCurrentPage(result.data?.current_page); // Set current page
                setTotalPages(result.data?.last_page); // Set total pages
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }

        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };

    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }
    const fetchSectorsData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setSectors(result);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchSavedJobs = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-saved-jobs`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,

                },
            });
            const result = await response.json();
            console.log('setSavedJobs', result)
            setSavedJobs(result?.saved_jobs || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSaveJob = async (id) => {
        try {
            const response = await fetch(`${apiUrl}api/toggle-saved-job/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                toast.success(data?.message);

                // Update savedJobs state in real time
                setSavedJobs((prevSavedJobs) =>
                    prevSavedJobs.includes(id)
                        ? prevSavedJobs.filter((jobId) => jobId !== id) // Remove the job ID if it was already saved
                        : [...prevSavedJobs, id] // Add the job ID if it wasn't saved
                );
            } else {
                const error = await response.json();
                console.error('Error toggling saved job:', error);
                toast.error('Failed to toggle save job. Please try again.');
            }
        } catch (err) {
            console.error('Error:', err);
            toast.error('Something went wrong. Please try again later.');
        }
    };



    useEffect(() => {
        fetchCountryStateList();
        fetchSectorsData();
        fetchFilteredProjects(); // Fetch data initially with filtering
        if (token) {
            fetchSavedJobs();
        }
    }, [token, selectedSector, selectedStage, selectedDate, currentPage]);

    return (
        <BasicLayout>
            <ToastContainer />
            {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
            <ShowApplyModal
                show={showModal}
                type='job'
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                companyName={companyName}
                projectId={projectId}
                positions={positions}
                handleDataFetch={fetchFilteredProjects}
            />
            <div className='inner-banner'>
                <div className='container'>
                    <h2>All Jobs</h2>
                </div>
            </div>
            <div className={showModal ? 'blurred-background' : ''}>
                <div className="container job-listing-wrapper mt-2">
                    <div className='row'>
                        <div className='col-md-3 sidebar position-sticky'>
                            <div className='project-filters'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>

                                            <div className='form-group location-fild col-md-12'>
                                                <label>Search by Location</label>
                                                <div className="form-group position-relative">
                                                    <Autocomplete
                                                        multiple // Allows multiple selections
                                                        options={stateCountryList}
                                                        freeSolo
                                                        className="flex-grow-1 bg-white"
                                                        value={selectedLocations}
                                                        onChange={(event, newValue) => {
                                                            setSelectedLocations(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="" />}
                                                    />
                                                    <div className="ms-2"> {/* Margin-left for spacing */}
                                                        <button className="btn btn-primary loc-serch mt-1" type="button" onClick={fetchFilteredProjects}>
                                                            <span class="material-symbols-outlined"> search </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group project-wise-search col-md-12 mt-4'>
                                                <div className="form-group position-relative">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search by position"
                                                        aria-describedby="button-addon2"
                                                        value={searchTerm}
                                                        onChange={handleSearchTermChange}
                                                    />
                                                    <button className="btn btn-primary" type="button" onClick={fetchFilteredProjects}>
                                                        <span class="material-symbols-outlined"> search </span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label>Search by Sector</label>
                                                <select
                                                    className="form-control mt-2"
                                                    id="type"
                                                    name="type"
                                                    value={selectedSector}
                                                    onChange={(event) => {
                                                        handleSectorChange(event);
                                                    }}
                                                >
                                                    <option value="">Select Sector</option>
                                                    {sectors.map((sector) => (
                                                        <option key={sector.name} value={sector.name}>{sector.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label>Search By Stage</label>
                                                <select
                                                    className="form-control mt-2"
                                                    id="stage"
                                                    name="stage"
                                                    value={selectedStage}
                                                    onChange={(event) => {
                                                        handleStageChange(event);
                                                    }}
                                                >
                                                    <option value="">Select Stage</option>
                                                    <option key='Current' value='Current'>Current</option>
                                                    <option key='Proposal' value='Proposal'>Proposal</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-12 mt-4">
                                                <select
                                                    className="form-control mt-2"
                                                    id="posted_date"
                                                    name="posted_date"
                                                    value={selectedDate}
                                                    onChange={(event) => {
                                                        handleDateChange(event);
                                                        fetchFilteredProjects(); // Trigger fetch on posted date change
                                                    }}
                                                >
                                                    <option value="">Posted Timeframe</option>
                                                    <option key='all' value='all'>All time</option>
                                                    <option key='recent' value='recent'>Recent</option>
                                                    <option key='last_week' value='last_week'>Last 7 days</option>
                                                    <option key='last_month' value='last_month'>Last 30 days</option>
                                                </select>
                                            </div>



                                            <div className='form-group col-md-12 mt-4'>
                                                <button
                                                    className="btn btn-warning mt-2"
                                                    onClick={() => {
                                                        setSelectedLocations([]);
                                                        function createSlug(name) {
                                                            return name.toLowerCase().replace(/\s+/g, '-');
                                                        } setSelectedSector('');
                                                        setSelectedStage('');
                                                        setSelectedDate('');
                                                        setSearchTerm('');
                                                        fetchFilteredProjects();
                                                        navigate('/all-jobs')
                                                    }}
                                                >
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            {data && data.length > 0 ? (
                                <>
                                    {data?.map((job, index) => (

                                        <div className="col-md-12 py-3" key={index}>
                                            <div className="card job-list shadow">
                                                <div className="card-body">
                                                    <div className="row align-items-center company-title">
                                                        <div className="col-md-2 comp-logo">
                                                            <div className="profile-wrapper" style={{ backgroundColor: job?.company?.logo ? '#fff' : '#fff', color: user?.details?.profile_picture ? '#fff' : '#000' }}>

                                                                {job?.company?.logo ? (
                                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${job?.company?.logo}`} alt="Profile picture" />
                                                                ) : (
                                                                    <p className='letter-cap'>{job?.company?.company_name?.charAt(0)}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7 job-title">
                                                            <Link to={`/job/${job?.slug}`}><h2>{job?.title}</h2> </Link>
                                                            <p>{job?.company?.company_name}</p>
                                                        </div>
                                                        <div className="col-md-3 post-meta">
                                                            <p>
                                                                <span class="badge bg-primary text-wrap me-auto">{job?.personnel_type == 'Both' ? 'National & International' : job?.personnel_type}</span>
                                                                <span className='project-status badge bg-secondary'>
                                                                    <i className="material-icons" title={job?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                    >info</i>  <span> {job?.stage}</span>
                                                                </span>
                                                            </p>
                                                            <p><i className="material-icons fs-6" title="Posted on">schedule</i>
                                                                <span className="fs-6 text-muted"> Posted on  {convertDate(job?.created_at)}</span></p>
                                                            {convertDate(job?.deadline) && (
                                                                <p>
                                                                    <i className="material-icons fs-6" title="Closing on">schedule</i>
                                                                    <span className="fs-6 text-muted"> Closing on  {convertDate(job?.deadline)}</span>
                                                                </p>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <hr className='my-1'></hr>
                                                    <div className='meta-info'>
                                                        <div className='row'>
                                                            <div className="col-md-3 pt-2">
                                                                <label>Sector</label>
                                                                <h6>{job?.sector}</h6>
                                                            </div>
                                                            <div className="col-md-3 pt-2">
                                                                <label>Sub Sector</label>
                                                                <h6>{job?.sub_sector}</h6>
                                                            </div>
                                                            <div className="col-md-3 pt-2">
                                                                <label>Location</label>
                                                                <h6>{job?.location}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mx-2' style={{ background: '#fafafa' }}>

                                                        {token ? (
                                                            user && user?.user?.role_id === 4 ? (
                                                                job?.applications?.some(application => application.user_id === user.user.id && application?.position == job?.title) ? (
                                                                    <>
                                                                        <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                                            <span className="text-success ">Application already submitted</span>
                                                                        </div>
                                                                    </>

                                                                ) : (

                                                                    <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                                        {/* <Link to={`/job/${createSlug(job?.title)}/project/${job?.slug}`}>
                                                                    <a className="btn btn-dark rounded-pill w-20">View Details</a>
                                                                </Link> */}
                                                                        <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30 mx-3" onClick={() => handleApply(job?.company?.company_name, job?.id, job?.title)} >
                                                                            Apply <i className="material-icons ml-2">arrow_forward</i>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            ) : null
                                                        ) : (
                                                            // <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                            //     <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30 mx-3" onClick={() => navigate('/sign-in')}>
                                                            //         Login to Apply <i className="material-icons ml-2">arrow_forward</i>
                                                            //     </button>
                                                            // </div>
                                                            <></>
                                                        )}
                                                    </div>
                                                    {/* Save Icon (Conditional Render) */}
                                                    {token && user && user?.user?.role_id === 4 && (
                                                        <div className="d-flex justify-content-end">
                                                            <i
                                                                className={`material-icons cursor-pointer ${savedJobs.includes(job?.id) ? 'text-warning' : 'text-dark'}`}
                                                                title={savedJobs.includes(job?.id) ? 'Job Saved' : 'Save Job'}
                                                                onClick={() => handleSaveJob(job?.id)}
                                                            >
                                                                bookmark
                                                            </i>
                                                        </div>
                                                    )}



                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )
                                :
                                <h2 className='text-center mt-3'>No Jobs found</h2>
                            }
                        </div>


                    </div>
                </div>
                <div className='container pagination'>
                    {/* Pagination */}
                    {totalPages > 1 && (
                        <MDPagination size="medium" className="page-list">
                            {currentPage > 1 && (
                                <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                                    <Icon>keyboard_arrow_left</Icon>
                                </MDPagination>
                            )}
                            {Array.from({ length: totalPages }, (_, i) => (
                                <MDPagination item
                                    key={i + 1}
                                    active={currentPage === i + 1}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </MDPagination>
                            ))}
                            {currentPage < totalPages && (
                                <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                                    <Icon>keyboard_arrow_right</Icon>
                                </MDPagination>
                            )}
                        </MDPagination>
                    )}
                </div>
            </div>

        </BasicLayout>

    );
};

export default Index;
