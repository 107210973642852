import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import MDButton from 'components/MDButton';
import MDAlert from "components/MDAlert";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { token, user } = useSelector(selectAuth);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      if (token) {
        const response = await fetch(`${apiUrl}api/employers`, {
          method: 'GET', // Adjust the HTTP method as needed
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        setData(result || []);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleApprove = async (employeeId, status) => {
    // Show a confirmation dialog
    setShowAlert(false)
    const isConfirmed = window.confirm(`Are you sure you want to ${status} this employer?`);

    if (isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}api/update-status-employer/${employeeId}/${status}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          // You can include credentials if needed
          // credentials: 'include',
        });
        const responseData = await response.json();

        if (response.ok) {
          fetchData();
          setShowAlert(true);
          setAlertMessage(responseData.message);
        } else {
          setShowAlert(true);
          setAlertMessage(responseData.message || 'Failed to approve employer');
        }
      } catch (error) {
        // Handle network error
        console.error('Network error:', error);
      }
    }
  };

  const handleDelete = async (employeeId) => {
    const confirmed = window.confirm('Are you sure you want to delete this employer?');
    if (!confirmed) return; // If user cancels, do nothing
    try {
      const response = await fetch(`${apiUrl}api/employers/${employeeId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        setShowAlert(true);
        setAlertMessage('Employer and company details deleted successfully');
        fetchData();
      } else {
        setShowAlert(true);
        setError(result.message || 'Failed to delete employer');
      }
    } catch (error) {
      console.error('Error deleting employer:', error);
      setShowAlert(true);
      setError('Failed to delete employer. Please try again later.');
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              {showAlert && error && (
                <>
                  <MDAlert color="danger" dismissible onClose={() => setShowAlert(false)}>
                    {error}
                  </MDAlert>
                </>
              )}
              {showAlert && (
                <>
                  <MDAlert color="success" dismissible onClose={() => setShowAlert(false)}>
                    {alertMessage}
                  </MDAlert>
                </>
              )}
              <div className="card-body">
                <DataTable
                  table={{
                    columns: [
                      { Header: "id", accessor: "id", width: "5%" },
                      { Header: "name", accessor: "name", width: "20%" },
                      { Header: "email", accessor: "email", width: "20%" },
                      { Header: "Company Name", accessor: "company_name", width: "20%" },
                      { Header: "Status", accessor: "status", width: "12%" },
                      { Header: "Action", accessor: "action", width: "20%" },
                    ],
                    rows: data?.map((employee) => ({
                      id: employee?.id || '',
                      name: employee?.name || '',
                      email: employee?.email || '',
                      company_name: employee?.company?.company_name || '',
                      status: employee?.is_approved === 0 ? 'Pending' : (employee?.is_approved === 1 ? 'Approved' : 'Rejected'),
                      action:
                        <>
                          <MDButton variant="gradient" color="info" onClick={() => navigate(`/employers/view/${employee.id}`)} >
                            <Icon>visibility</Icon>&nbsp;
                          </MDButton>

                          {(employee.is_approved === 0 || employee.is_approved === 2) && (
                            <MDButton
                              variant="gradient"
                              title="Approve"
                              color="info"
                              onClick={() => handleApprove(employee.id, 'approve')}
                              className="mx-2"
                            >
                              <Icon>check</Icon>&nbsp;
                            </MDButton>
                          )}

                          {(employee.is_approved === 0 || employee.is_approved === 1) && (
                            <MDButton
                              variant="gradient"
                              color="warning"
                              onClick={() => handleApprove(employee.id, 'reject')}
                              className="mx-2"
                              title="Reject"
                            >
                              <Icon>close</Icon>&nbsp;
                            </MDButton>
                          )}



                          <MDButton variant="gradient" color="error" onClick={() => handleDelete(employee.id)} className={employee.is_approved === 1 ? "mx-2" : ""}>
                            <Icon>delete</Icon>&nbsp;
                          </MDButton>
                        </>
                    })),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </DashboardLayout>
  )
}

export default Index