import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import axios from 'axios';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip, IconButton, FormControl, InputLabel, CircularProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Country } from 'country-state-city';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { login } from '../../../../store/slices/authSlice';
import CustomLoader from "components/CustomLoader";
import BasicLayout from 'layouts/authentication/components/BasicLayout';

function JobSeekerRegistration({ handleRegistrationType }) {
    const [seekerFormData, setSeekerFormData] = useState({
        name: '',
        email: '',
        password: '',
        role_id: 4,
        phone: '',
        designation: '',
        years_of_experience: '',
        citizenship: '',
        languages: [],
    });
    const [seekererror, setSeekerError] = useState(null);
    const [seekerVerify, setSeekerVerify] = useState(false);
    const [seekerOtpSend, setSeekerOtpSend] = useState(false);
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(180); // 3 minutes in seconds
    const [showTimer, setShowTimer] = useState(false);
    const [proceedClicked, setProceedClicked] = useState(false);
    const countries = Country.getAllCountries();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const navigate = useNavigate();

    const handleSeekerChange = (e) => {
        const { name, value } = e.target;
        setSeekerFormData({ ...seekerFormData, [name]: value });
    };

    const handleOtp = (e) => {
        setOtp(e.target.value);
    };

    const handleSeekerSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${apiUrl}api/user-register`, seekerFormData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Ensure correct content type
                    }
                }
            );
            if (response.status === 200) {
                const token = response.data.token;
                // localStorage.setItem('token', token);
                if (token) {
                    const cookies = new Cookies();
                    cookies.set('token', token, { path: '/' });
                    handleLogin(token)
                    navigate('/profile-completion');

                }
            }
        } catch (error) {
            if (error.response) {
                setSeekerError(error.response.data.error);
            } else if (error.request) {
                setSeekerError('No response received from the server');
            } else {
                setSeekerError('An error occurred while making the request');
            }
        }
    };

    const handleLogin = async (newToken) => {
        try {
            const response = await fetch(`${apiUrl}api/profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${newToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            const { token, user } = data;
            const isNewUser = true;
            if (user && token) {
                dispatch(login({ user, token, isNewUser }));
            }
        } catch (error) {

            console.error('Error fetching data:', error);
        }
    }

    const sendOtp = async () => {
        setLoading(true);
        setSeekerError('');
        try {
            const response = await axios.post(`${apiUrl}api/send-otp`, { email: seekerFormData['email'] });
            if (response.status === 200) {
                setLoading(false);
                setSeekerOtpSend(true);
                setShowTimer(true);
            }
        } catch (error) {
            if (error.response) {
                setLoading(false);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors)
                    setSeekerError(error.response.data.errors);
                } else {
                    setSeekerError(error.response.data.error);
                }
            } else if (error.request) {
                setLoading(false);
                setSeekerError('No response received from the server');
            } else {
                setLoading(false);
                setSeekerError('An error occurred while making the request');
            }
        }
    };

    const handleVerify = async () => {
        try {
            const response = await axios.post(`${apiUrl}api/verify-otp`, {
                email: seekerFormData['email'],
                otp: otp
            });
            if (response.status === 200) {
                setSeekerVerify(true);
                setShowTimer(false);
            }
        } catch (error) {
            if (error.response) {
                setSeekerError(error.response.data.message);
            } else if (error.request) {
                setSeekerError('No response received from the server');
            } else {
                setSeekerError('An error occurred while making the request');
            }
        }
    };

    const validatePassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (!passwordRegex.test(seekerFormData.password)) {
            setSeekerError({ password: ['Password format is invalid'] });
            return false;
        }
        if (!seekerFormData.name.trim()) {
            setSeekerError({ name: ['Name is required'] });
            return false;
        }
        if (!seekerFormData.phone.trim()) {
            setSeekerError({ phone: ['Phone Number is required'] });
            return false;
        }
        return true;
    };


    const handleProceed = () => {
        console.log(validatePassword, validatePassword())
        if (!validatePassword()) {
            setProceedClicked(false)

        }
        else {
            setProceedClicked(true)
            setSeekerError('')

        }

    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    useEffect(() => {
        let countdown;
        if (showTimer && timer > 0) {
            countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [showTimer, timer]);

    useEffect(() => {
        if (timer === 0) {
            setSeekerOtpSend(false);
            setShowTimer(false);
            setTimer(180); // Reset timer for next use
        }
    }, [timer]);

    return (
        <BasicLayout>
            {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
            <div className='signup-wrapper'>
                <div className='container'>
                    <div className='row vh-100 d-flex align-items-center justify-content-center'>
                        <div className='col-md-4 signup-box mb-3'>
                            <div className='sign-up-box-header'>
                                <p>Register as a Professional</p>
                            </div>
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-md-12 left-content p-2'>
                                    <MDBox pt={2} pb={3} px={3}>
                                        <MDBox component="form" role="form">
                                            {!proceedClicked ? (
                                                <>
                                                    <MDBox mb={2}>
                                                        <MDInput
                                                            type="text"
                                                            label="Name"
                                                            name="name"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={seekerFormData.name}
                                                            onChange={handleSeekerChange}
                                                            seekererror={seekererror && seekererror.name}
                                                            helperText={seekererror && seekererror.name && (
                                                                <MDTypography variant="caption" color="error">
                                                                    {seekererror.name[0]}
                                                                </MDTypography>
                                                            )}
                                                            required
                                                        />
                                                    </MDBox>
                                                    <MDBox display="flex" alignItems="center" mb={2}>
                                                        <MDInput
                                                            type="email"
                                                            label="Email"
                                                            name="email"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={seekerFormData.email}
                                                            onChange={handleSeekerChange}
                                                            seekererror={seekererror && seekererror.email}
                                                            required
                                                            disabled={seekerVerify}
                                                        />

                                                        {!showTimer && !seekerVerify ? (
                                                            <MDButton variant="gradient" color="dark" onClick={sendOtp} ml={2}>
                                                                Verify
                                                            </MDButton>
                                                        ) : ''}

                                                    </MDBox>
                                                    {seekererror && seekererror.email && (
                                                        <MDTypography variant="caption" color="error" mb={2} mt={2}>
                                                            {seekererror.email[0]}
                                                        </MDTypography>
                                                    )}
                                                    {showTimer && seekerOtpSend && (
                                                        <MDBox display="flex" alignItems="center" mb={2} >
                                                            <MDInput
                                                                type="text"
                                                                label="OTP"
                                                                name="otp"
                                                                variant="outlined"
                                                                fullWidth
                                                                onChange={handleOtp}
                                                                helperText={seekererror ? (
                                                                    <MDTypography variant="caption" color="error">
                                                                        {seekererror}
                                                                    </MDTypography>
                                                                ) :
                                                                    <MDTypography variant="caption" color="success">
                                                                        OTP has been sent to your email
                                                                    </MDTypography>
                                                                }
                                                                required
                                                            />
                                                            <MDButton variant="gradient" color="dark" onClick={handleVerify} ml={2}>
                                                                Confirm
                                                            </MDButton>
                                                        </MDBox>
                                                    )}
                                                    {showTimer && (
                                                        <div>
                                                            <p>Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}</p>
                                                        </div>
                                                    )}
                                                    <MDBox mb={2}>
                                                        <MDInput
                                                            type="text"
                                                            label="Phone Number"
                                                            name="phone"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={seekerFormData.phone}
                                                            onChange={handleSeekerChange}
                                                            seekererror={seekererror && seekererror.phone}
                                                            helperText={seekererror && seekererror.phone && (
                                                                <MDTypography variant="caption" color="error">
                                                                    {seekererror.phone[0]}
                                                                </MDTypography>
                                                            )}
                                                            required
                                                        />
                                                    </MDBox>
                                                    <MDBox mb={2} sx={{ position: 'relative' }}>
                                                        <MDInput
                                                            type="password"
                                                            label="Password"
                                                            name="password"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={seekerFormData.password}
                                                            onChange={handleSeekerChange}
                                                            seekererror={seekererror && seekererror.password}
                                                            helperText={seekererror && seekererror.password && (
                                                                <MDTypography variant="caption" color="error">
                                                                    {seekererror.password[0]}
                                                                </MDTypography>
                                                            )}
                                                            required
                                                        />
                                                        <Tooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <div>The password must meet the following criteria:</div>
                                                                    <ul>
                                                                        <li>The password contains at least one lowercase letter (a-z)</li>
                                                                        <li>The password contains at least one uppercase letter (A-Z)</li>
                                                                        <li>The password contains at least one digit (0-9)</li>
                                                                        <li>The password contains at least one special character</li>
                                                                        <li>The password is at least 8 characters long</li>
                                                                    </ul>
                                                                </React.Fragment>
                                                            }
                                                            arrow
                                                            placement="right"
                                                        >
                                                            <IconButton sx={{ position: 'absolute', top: '50%', right: 8, transform: 'translateY(-50%)' }}>
                                                                <InfoIcon sx={{ fontSize: 16, color: 'dark' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </MDBox>
                                                </>
                                            ) : (
                                                <>
                                                    <MDBox mb={2}>
                                                        <MDInput
                                                            type="number"
                                                            label="Total year of exprience"
                                                            name="years_of_experience"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={seekerFormData.years_of_experience}
                                                            onChange={handleSeekerChange}
                                                            seekererror={seekererror && seekererror.years_of_experience}
                                                            helperText={seekererror && seekererror.years_of_experience && (
                                                                <MDTypography variant="caption" color="error">
                                                                    {seekererror.years_of_experience[0]}
                                                                </MDTypography>
                                                            )}
                                                            required
                                                        />
                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput
                                                            type="text"
                                                            label="Designation"
                                                            name="designation"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={seekerFormData.designation}
                                                            onChange={handleSeekerChange}
                                                            seekererror={seekererror && seekererror.designation}
                                                            helperText={seekererror && seekererror.designation && (
                                                                <MDTypography variant="caption" color="error">
                                                                    {seekererror.designation[0]}
                                                                </MDTypography>
                                                            )}
                                                        />
                                                    </MDBox>
                                                    <MDBox mb={2}>
                                                        <MDInput
                                                            select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Citizenship"
                                                            name="citizenship"
                                                            value={seekerFormData.citizenship}
                                                            onChange={handleSeekerChange}

                                                            InputProps={{
                                                                classes: { root: "select-input-styles" },
                                                            }}
                                                            fullWidth
                                                            required
                                                        >
                                                            {countries.map((country, index) => (
                                                                <MenuItem value={country.name}>{country.name}</MenuItem>
                                                            ))}

                                                        </MDInput>
                                                    </MDBox>

                                                </>
                                            )}
                                            {/* <MDBox display="flex" alignItems="center" ml={-1}>
                        <Checkbox />
                        <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                            &nbsp;&nbsp;I agree the&nbsp;
                        </MDTypography>
                        <MDTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                        >
                            Terms and Conditions
                        </MDTypography>
                    </MDBox> */}

                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="privacyPolicyCheckbox"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="privacyPolicyCheckbox">
                                                    <small className='text-muted'>I agree to the <a href="/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>
                                                    </small></label>
                                            </div>
                                            <MDBox mt={4} mb={1}>
                                                {proceedClicked ? (
                                                    <MDBox display="flex" justifyContent="space-between">
                                                        <MDButton variant="gradient" color="dark" onClick={() => setProceedClicked(false)}>
                                                            Back
                                                        </MDButton>
                                                        <MDButton variant="gradient" color="info" onClick={handleSeekerSubmit}>
                                                            Register
                                                        </MDButton>
                                                    </MDBox>
                                                ) : (
                                                    <>
                                                        <MDBox display="flex" justifyContent="space-between">
                                                            <MDButton variant="gradient" color="dark" onClick={() => navigate('/sign-up')}>
                                                                Back
                                                            </MDButton>
                                                            <MDButton
                                                                variant="gradient"
                                                                color="info"
                                                                onClick={handleProceed}
                                                                disabled={!seekerVerify}
                                                            >
                                                                Proceed
                                                            </MDButton>
                                                        </MDBox>
                                                    </>
                                                )}
                                            </MDBox>
                                            <MDBox mt={3} mb={1} textAlign="center">
                                                <MDTypography variant="button" color="text">
                                                    Already have an account?{" "}
                                                    <MDTypography
                                                        component={Link}
                                                        to="/sign-in"
                                                        variant="button"
                                                        color="info"
                                                        fontWeight="medium"
                                                        textGradient
                                                    >
                                                        Sign In
                                                    </MDTypography>
                                                </MDTypography>
                                            </MDBox>
                                        </MDBox>

                                    </MDBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </BasicLayout>

    );
}

export default JobSeekerRegistration;
