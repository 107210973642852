

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/login-bg.jpg";

import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../../../store/slices/authSlice';
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSignIn = async () => {
    try {
      const response = await axios.post(`${apiUrl}api/login`, {
        email: email,
        password: password
      });

      const { token, user } = response.data;
      if (user && token) {
        dispatch(login({ user, token }));
        const can = (permission) => (user && user.permissions) ? user.permissions.find((p) => p === permission) : false;
        const cookies = new Cookies();
        cookies.set('token', token, { path: '/' });
        if (can('view dashboard')) {
          navigate('/dashboard')

        }
        else {
          const redirectPath = new URLSearchParams(location.search).get('from');
          if (redirectPath) {
            navigate(redirectPath)
          }
          else {
          if(user?.profile_completed == 0){
            navigate('/profile-completion')
            toast.warning("Please complete your profile!", {
              className: "toast-message",
            });
          }
          else{
            const redirectTo = user?.role_id === 4 ? '/profile' : '/my-profile';
            console.log("redirectTo",redirectTo)
            navigate(redirectTo)
          }

          }

        }

        // if(user.role_id == 1){
        // navigate('/dashboard')
        // }
        // localStorage.setItem('token', token)
      }

      // Handle successful login (e.g., store token in localStorage, redirect, etc.)
      console.log('Login successful:', user);

      // Handle successful login (e.g., store token in localStorage, redirect, etc.)
      // console.log('Login successful:', user);

    } catch (error) {
      if (error.response) {
        if (error.response.status === 403 && error.response.data.error === 'account_deleted') {
          navigate('/account-recover');
        } 
        else if (error.response.status === 403) {
          setError(error.response.data.error);
        }else if (error.response.status === 422) {
          setError(error.response.data.error);
        }else if (error.response.status === 401) {
          console.log('dd',error.response.data.error)
          setError(error.response.data.error);
        }
         else {
          setError('An error occurred while making the request');
        }
      } else {
        setError('An error occurred while making the request');
      }
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <div className="login-wrapper">
        <div className="container">
          <div className="row vh-100 d-flex align-items-center justify-content-center">
            <div className="col-md-8 login-box">
              <div className="row d-flex align-items-center justify-content-center">
              <div className="col-md-7 p-5">
                <h4>Wellcome Back to Proxtal</h4>
                <p>The First ever Construction And Civil job Management Portal</p>
              </div>
            <div className="col-md-5 p-4">
              <div className="login">
                <h4>Sign in</h4>
                <hr></hr>
                <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput type="email" label="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
                  {error && (
                    <MDTypography variant="caption" color="error">
                      {error.message}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="password" label="Password" fullWidth onChange={(e) => setPassword(e.target.value)} />
                  {error && error.password && (
                    <MDTypography variant="caption" color="error">
                      {error.password}
                    </MDTypography>
                  )}
                </MDBox>
                      {/* <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox> */}
                <MDBox mt={4} mb={1}>
                  <MDButton variant="btn btn-warning" color="info" fullWidth onClick={handleSignIn}>
                    sign in
                  </MDButton>
                  {error && (
                    <MDTypography variant="caption" color="error">
                      {error}
                    </MDTypography>
                  )}
                </MDBox>

                <MDBox mb={1}>
                  <MDTypography
                    component={Link}
                    to="/forgot-password"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient

                  >
                    Forgot password?
                  </MDTypography>
                  <br></br>
                  <MDTypography variant="button" color="text">
                    Don&apos;t have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-up"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign up
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
              </div>
            <Card className="mx-auto">
            {/* <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            > */}
              
              {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
            {/* </MDBox> */}
          </Card>
            </div>
              </div>
            </div>
           
          
        
          </div>
        </div>
      </div>
          
    </BasicLayout>
  );
}

export default Basic;
