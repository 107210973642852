import React, { useState, useEffect } from 'react';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import defaultImage from "../../assets/images/news-default.png"
import CustomLoader from 'components/CustomLoader'
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import { MultiSelect } from 'react-multi-select-component';

const options = [
    { label: "Agriculture and natural resources", value: "Agriculture and natural resources" },
    { label: "Capacity development", value: "Capacity development" },
    { label: "Climate change", value: "Climate change" },
    { label: "Economics", value: "Economics" },
    { label: "Education", value: "Education" },
    { label: "Energy", value: "Energy" },
    { label: "Environment", value: "Environment" },
    { label: "Evaluation", value: "Evaluation" },
    { label: "Finance sector development", value: "Finance sector development" },
    { label: "Gender Equality", value: "Gender Equality" },
    { label: "Governance and public sector management", value: "Governance and public sector management" },
    { label: "Health", value: "Health" },
    { label: "Industry and trade", value: "Industry and trade" },
    { label: "Information and Communications Technology", value: "Information and Communications Technology" },
    { label: "Poverty", value: "Poverty" },
    { label: "Private sector development", value: "Private sector development" },
    { label: "Regional cooperation and integration", value: "Regional cooperation and integration" },
    { label: "Social development and protection", value: "Social development and protection" },
    { label: "Transport", value: "Transport" },
    { label: "Urban development", value: "Urban development" },
    { label: "Rural Development", value: "Rural Development" },
    { label: "Water", value: "Water" },
    { label: "Career", value: "Career" },
    { label: "Funding", value: "Funding" },
    { label: "Research", value: "Research" },
    { label: "Institutional Development", value: "Institutional Development" },
    { label: "Infrastructure", value: "Infrastructure" },
    { label: "Humanitarian Aid", value: "Humanitarian Aid" }
];

function Index() {
    const [modal, setModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingNews, setEditingNews] = useState(null);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [content, setContent] = useState(null);
    const [contentError, setContentError] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [attachmentError, setAttachmentError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [newsItems, setNewsItems] = useState([])
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);
    const [selectedSector, setSelectedSector] = useState([]);
    const [sectorError, setSectorError] = useState(false);
    const [countries, setCountries] = useState([]);
    const [location, setSelectedLocation] = useState('');
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [filterSelectedSector, setFilterSelectedSector] = useState('');
    const [filterSelectedLocation, setFilterSelectedLocation] = useState('');


    const toggleModal = (newsItem = null) => {
        if (newsItem?.id) {
            // Edit mode
            setIsEditMode(true);
            setEditingNews(newsItem);
            setTitle(newsItem.title);
            setContent(newsItem.content);
            // setSelectedSector(newsItem.sectors || []);
            setSelectedLocation(newsItem.location || '');
            const preSelectedSectors = options.filter(option =>
                newsItem?.sectors?.includes(option.value)
            );
            setSelectedSector(preSelectedSectors);
            // Don't reset the image unless a new one is uploaded
        } else {
            // Create mode
            setIsEditMode(false);
            setEditingNews(null);
            setTitle('');
            setContent('');
            setSelectedSector([]); 
            setSelectedLocation('');
            setAttachment(null);
        }
        setModal(!modal);
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!title.trim()) {
    //         setTitleError('Title is required')
    //         setContentError('')
    //         return;
    //     }
    //     if (!selectedSector.trim()) {
    //         setTitleError('')
    //         setSectorError(true);
    //         return;
    //     }

    //     if (!content.trim()) {
    //         setTitleError('')
    //         setSectorError(false);
    //         setContentError('Content is required')
    //         return;
    //     }

    //     if (attachment && attachment.size > 1024 * 1024) {
    //         setContentError('')
    //         setTitleError('')
    //         setSectorError(false);
    //         setAttachmentError('File size should not be more than 1 MB.');
    //         return;
    //     }

    //     if (
    //         attachment &&
    //         !['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(attachment.type)
    //     ) {
    //         setContentError('')
    //         setTitleError('')
    //         setSectorError(false);
    //         setAttachmentError('Only JPG, JPEG, PNG, and WEBP files are allowed.');
    //         return;
    //     }


    //     setTitleError('')
    //     setContentError('')
    //     setAttachmentError('')
    //     setSectorError(false);
    //     // Handle form submission logic here
    //     const formData = new FormData();
    //     formData.append('title', title);
    //     formData.append('content', content);
    //     formData.append('image', attachment);
    //     formData.append('sector', selectedSector);
    //     formData.append('location', location);
    //     console.log(attachment)


    //     try {
    //         await axios.post(`${apiUrl}api/news/store-news`, formData, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         });
    //         setTimeout(() => {
    //             fetchAllNews();
    //         }, 1000);
    //         toggleModal();
    //         toast.success('News Published Successfully.');
    //         setTitle('')
    //         setContent('')
    //         setAttachment(null)
    //     } catch (error) {
    //         console.error('Error creating project:', error);

    //     }


    // };
    const handleDateChange = (event) => {
        const value = event.target.value;
        setSelectedDate(value);
    };
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value); // Added handler for search term change
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        if (attachment) {
            formData.append('image', attachment);
        }
        if (editingNews?.image == null) {
            formData.append('remove_image', true);
        }
        formData.append('sectors', selectedSector.map(sector => sector.value));
        formData.append('location', location);

        try {
            if (isEditMode) {
                await axios.post(`${apiUrl}api/news/update-news/${editingNews.id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                toast.success('News Updated Successfully.');
            } else {
                await axios.post(`${apiUrl}api/news/store-news`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                toast.success('News Published Successfully.');
            }

            setTimeout(() => {
                fetchAllNews();
            }, 1000);
            toggleModal();
        } catch (error) {
            console.error('Error:', error);
            toast.error('Something went wrong!');
        }
    }

    const destroyNews = async (id) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the news?`);
        if (!isConfirmed) return;
        try {

            const response = await fetch(`${apiUrl}api/news/destroy/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();

            if (result) {
                toast.success('News deleted Successfully.');
                setTimeout(() => {
                    fetchAllNews();
                }, 1000);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleAttachmentChange = (e) => {
        const file = e.target.files[0];
        const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
        const maxFileSize = 1024 * 1024; // 1 MB

        if (!allowedExtensions.includes(file.type)) {
            setAttachmentError('Only JPG, JPEG, PNG, and WEBP files are allowed.');
            setAttachment(null);
        } else if (file.size > maxFileSize) {
            setAttachmentError('File size should not be more than 1 MB.');
            setAttachment(null);
        } else {
            setAttachment(file);
            setAttachmentError('');
        }
    };

    const fetchAllNews = async (e) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();

            if (filterSelectedLocation) {
                queryParams.append('location', filterSelectedLocation);
            }

            if (filterSelectedSector) {
                queryParams.append('sector', filterSelectedSector);
            }

            if (selectedDate) {
                queryParams.append('posted_date', selectedDate);
            }

            if (searchTerm) {
                queryParams.append('search', searchTerm); // Added project name filter
            }
            queryParams.append('page', currentPage || 1);
            queryParams.append('items_per_page', itemsPerPage || 20);
            const response = await fetch(`${apiUrl}api/news?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                setNewsItems(result?.data || []);
                setCurrentPage(result.data?.current_page); // Set current page
                setTotalPages(result.data?.last_page); // Set total pages
                console.log(newsItems);
            }
            setTimeout(() => {
                setLoading(false);
            }, 2000);

        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            console.error('Error fetching data:', error);
        }
    }
    const convertDate = (date) => {
        const data = date;
        const formattedDeadline = data ? format(parseISO(data), 'eeee, MMMM d, yyyy') : '';
        return formattedDeadline;
    }

    const fetchCountries = async () => {
        try {
            const response = await fetch(`${apiUrl}api/countries`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                setCountries(result)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchAllNews();
        fetchCountries();
        if (!modal) {
            // Reset form when modal is closed
            setIsEditMode(false);
            setEditingNews(null);
            setTitle('');
            setContent('');
            setSelectedSector([]); 
            setSelectedLocation('');
            setAttachment(null);
            setTitleError('');
            setContentError('');
            setAttachmentError('');
            setSectorError(false);
        }

    }, [token, currentPage, modal, filterSelectedSector, filterSelectedLocation, selectedDate]);
    return (
        <BasicLayout>
            <ToastContainer />
            {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
            <div className="inner-banner">
                <div className="container">
                    <h2>News Feed</h2>
                </div>
            </div>
            <div className="container news-listing-wrapper mt-2">
                <div className={modal ? 'blurred-background' : ''}>

                    <div className="row justify-content-center">
                        {token && (
                            <div className="col-md-6 mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            {user?.user?.profile ?
                                                <img
                                                    src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.user?.profile}`}
                                                    alt="Profile"
                                                    className="rounded-circle me-2"
                                                    style={{ width: '10%', height: '10%', objectFit: 'cover' }}
                                                />
                                                :
                                                <div
                                                    className="d-flex align-items-center justify-content-center rounded-circle me-2"
                                                    style={{
                                                        width: '65px',
                                                        height: '60px',
                                                        backgroundColor: '#f7931e',
                                                        color: '#fff',
                                                        fontSize: '24px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {user?.user?.name?.charAt(0).toUpperCase()}
                                                </div>
                                            }

                                            <input
                                                type="text"
                                                className="form-control border-0"
                                                placeholder="What's in your mind? Start a Post"
                                                onClick={toggleModal}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-end mt-1">
                                            <div
                                                className="d-flex align-items-center"
                                                style={{ cursor: 'pointer' }}
                                                onClick={toggleModal}
                                            >
                                                <i className="fas fa-newspaper me-2"></i>
                                                <span>Write News</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row justify-content-center">
                            <div className='col-md-9 mt-3 project-filters'>
                                <div className='card'>
                                    <div className='card-title text-center'>
                                        {/* <h3>Looking for Projects?</h3> */}
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='form-group project-wise-search col-md-12'>
                                                <div className="form-group position-relative mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search by keyword"
                                                        aria-describedby="button-addon2"
                                                        value={searchTerm}
                                                        onChange={handleSearchTermChange}
                                                    />
                                                    <button className="btn btn-primary" type="button" onClick={fetchAllNews}>
                                                        <span class="material-symbols-outlined"> search </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className='form-group col-md-4'>
                                                <label>Search by Location</label>
                                                <select className="form-control" id="type" name="type" value={filterSelectedLocation} onChange={(e) => setFilterSelectedLocation(e.target.value)} required>
                                                    <option value="">Select Location</option>
                                                    {countries.map(country => (
                                                        <option key={country.id} value={country.name}>{country.name}</option>
                                                    ))}
                                                </select>
                                                {/* <div className="form-group position-relative">
                                                    <Autocomplete
                                                        multiple // Allows multiple selections
                                                        // options={stateCountryList}
                                                        freeSolo
                                                        // value={selectedLocations}
                                                        // onChange={(event, newValue) => {
                                                        //     setSelectedLocations(newValue);
                                                        // }}
                                                        // renderInput={(params) => <TextField {...params} label="" />}
                                                    />
                                                    <button className="btn btn-primary loc-serch mt-1" type="button" onClick={fetchAllNews}>
                                                        <span class="material-symbols-outlined"> search </span></button>
                                                </div> */}
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>Search by Topic</label>
                                                <select className="form-control" id="type" name="type" value={filterSelectedSector} onChange={(e) => setFilterSelectedSector(e.target.value)} required>
                                                    <option value="">Select Topic</option>
                                                    {options.map(sector => (
                                                        <option value={sector.value}>{sector.value}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3">
                                                <label>Search by Date</label>
                                                <select
                                                    className="form-control"
                                                    id="posted_date"
                                                    name="posted_date"
                                                value={selectedDate}
                                                onChange={(event) => {
                                                    handleDateChange(event);
                                                    fetchAllNews(); // Trigger fetch on posted date change
                                                }}
                                                >
                                                    <option value="">Posted Timeframe</option>
                                                    <option key='all' value='all'>All time</option>
                                                    <option key='recent' value='recent'>Recent</option>
                                                    <option key='last_week' value='last_week'>Last 7 days</option>
                                                    <option key='last_month' value='last_month'>Last 30 days</option>
                                                </select>
                                            </div>



                                            <div className='form-group col-md-2 mt-3'>
                                                <button
                                                    className="btn btn-warning mt-3"
                                                    onClick={() => {
                                                        setSearchTerm('');
                                                        setSelectedDate('');
                                                        setFilterSelectedSector('');
                                                        setFilterSelectedLocation('');
                                                        fetchAllNews();
                                                    }}
                                                >
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-12 mt-5">
                            <div className='row'>
                                {newsItems.map((news, index) => (
                                    <div className="col-md-6 mb-5">
                                        <div key={index} className="card mb-3" style={{ maxWidth: '800px', margin: '0 auto' }}>
                                            {/* Options Menu */}
                                            {user?.user?.id === news?.user_id && (
                                                <div className="position-absolute top-0 end-0 p-2">
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-link text-dark p-0"
                                                            onClick={(e) => {
                                                                setOpenDropdownId(openDropdownId === index ? null : index);
                                                            }}
                                                            aria-expanded={openDropdownId === index}
                                                        >
                                                            <span class="material-symbols-outlined">
                                                                more_vert
                                                            </span>
                                                        </button>

                                                        {openDropdownId === index && (
                                                            <div className="dropdown-menu show position-absolute" style={{ left: 0 }}>
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() => {
                                                                        toggleModal(news);
                                                                        setOpenDropdownId(null);
                                                                    }}
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    className="dropdown-item text-danger"
                                                                    onClick={() => {
                                                                        destroyNews(news?.id)
                                                                        setOpenDropdownId(null);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="row g-0">
                                                <div className="col-md-3" style={{ position: 'relative' }}>
                                                    <div style={{
                                                        height: '140px',
                                                        overflow: 'hidden',
                                                        position: 'relative'
                                                    }}>
                                                        <img
                                                            src={
                                                                news?.image
                                                                    ? `${apiUrl}${node === 'production' ? 'public/' : ''}storage/${news?.image}`
                                                                    : defaultImage
                                                            }
                                                            className="img-fluid rounded-start"
                                                            alt={news?.title}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover'
                                                            }}
                                                        />

                                                    </div>

                                                </div>
                                                <div className="col-md-9">
                                                    <div className="card-body">
                                                        <Link to={`/news-feed/${news?.slug}`}>
                                                            <h5 className="card-title" style={{
                                                                fontSize: '16px',
                                                                fontWeight: 'bold',
                                                                marginBottom: '8px'
                                                            }}>
                                                                {news?.title}
                                                            </h5>
                                                        </Link>
                                                        <p className='text-muted'>By {news?.user?.name} || {convertDate(news?.created_at)}</p>
                                                        <p
                                                            className="card-text"
                                                            dangerouslySetInnerHTML={{
                                                                __html: news?.content?.length > 100
                                                                    ? `${news.content.substring(0, 100)}...`
                                                                    : news.content
                                                            }}
                                                        ></p>
                                                        <div className="d-flex align-items-center g-0">
                                                            <p className="mb-0 flex-grow-1 font-italic">
                                                                {news?.location}
                                                            </p>
                                                        </div>
                                                        <p className="mb-0 flex-grow-1 font-italic">
                                                            {news?.sectors?.map((sector, index) => (
                                                                <span key={index} className="badge bg-dark text-white me-1" style={{ padding: '5px 10px', borderRadius: '5px' }}>
                                                                    {sector}
                                                                </span>
                                                            ))}
                                                        </p>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modal && (
                <div
                    className="modal fade show bd-example-modal-lg"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{isEditMode ? 'Edit News' : 'Post News'}</h5>
                                <button type="button" className="btn-close" onClick={toggleModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="title">
                                            Title <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="title"
                                            name="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                            maxLength={200}
                                        />
                                        {titleError && (
                                            <p className="text-danger mt-2"><small>{titleError}</small> </p>
                                        )}
                                    </div>

                                    <div className='form-group col-md-6'>
                                        <label htmlFor="sector">Topic <span className='text-danger'>*</span></label>
                                       {/* <select className="form-control mt-2" id="type" name="type" value={selectedSector} onChange={(e) => setSelectedSector(e.target.value)} required>
                                            <option value="">Select Topic</option>
                                            {options.map(sector => (
                                                <option value={sector.value}>{sector.value}</option>
                                            ))}
                                        </select> */}
                                         <MultiSelect 
                                            options={options}
                                            value={selectedSector}
                                            name="sectors"
                                            onChange={setSelectedSector}
                                            labelledBy="Select"
                                            className={`mt-2 `}
                                            required
                                        /> 
                                        {sectorError && <p className="text-danger mt-2"><small>Please select at least one sector.</small> </p>}

                                    </div>

                                    <div className="form-group col-md-12 mt-4">
                                        <label htmlFor="floatingTextarea2">
                                            Content <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-floating h-100 mt-2">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={content}
                                                required
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setContent(data);
                                                }}
                                            />
                                        </div>
                                        {contentError && (
                                            <p className="text-danger mt-2"><small>{contentError}</small> </p>
                                        )}
                                    </div>

                                    <div className="form-group col-md-6  mt-4">
                                        <label htmlFor="type">Country </label>
                                        <select className="form-control mt-2" id="type" name="type" value={location} onChange={(e) => setSelectedLocation(e.target.value)} required>
                                            <option value="">Select Country</option>
                                            {countries.map(country => (
                                                <option key={country.id} value={country.name}>{country.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6 mt-4">
                                        <label htmlFor="attachment">Attachment</label>
                                        <div className="input-group mt-2">
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="attachment"
                                                accept="image/jpeg, image/jpg, image/png, image/webp"
                                                onChange={handleAttachmentChange}
                                            />

                                        </div>
                                        {attachmentError && (
                                            <p className="text-danger mt-2"><small>{attachmentError}</small> </p>
                                        )}
                                        {isEditMode && editingNews?.image && (
                                            <div className="mt-2">
                                                <img
                                                    src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${editingNews?.image}`}
                                                    alt="Current"
                                                    style={{ maxHeight: '100px', display: 'block', marginTop: '5px' }}
                                                />
                                                <button className="btn btn-link text-danger"
                                                    onClick={() => {
                                                        setEditingNews((prev) => ({
                                                            ...prev,
                                                            image: null,
                                                        }));
                                                        setAttachment(null);
                                                    }}

                                                    style={{ padding: 0, textDecoration: 'none' }}>
                                                    Remove Picture
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                    {isEditMode ? 'Update' : 'Submit'}
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </BasicLayout>
    );
}

export default Index;