import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Country, State } from 'country-state-city';
import { Autocomplete, TextField } from '@mui/material';
import countryData from '../../assets/data/country-state.json';
import EditExperience from 'layouts/seeker/components/profile/EditExperience';
import { toast, ToastContainer } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';

const ProfileCompletionForProfessional = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const { token, user } = useSelector(selectAuth);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
  const [skills, setSkills] = useState([]);

  const startDate = `${currentYear}-01`;
  // Format the end date
  const endDate = `${currentYear}-${currentMonth}`;
  const sectorOptions = [
    { label: "Agriculture", value: "Agriculture" },
    { label: "Finance", value: "Finance" },
    { label: "Education", value: "Education" },
    { label: "Energy & Extractives", value: "Energy & Extractives" },
    { label: "Water/Sanit/Waste", value: "Water/Sanit/Waste" },
    { label: "Transportation", value: "Transportation" },
    { label: "Info & Communication", value: "Info & Communication" },
    { label: "Industry & Trade/Service", value: "Industry & Trade/Service" },
    { label: "Health", value: "Health" }
  ];

  const [formData, setFormData] = useState({
    // Personal Information
    name: '',
    phone: '',
    citizenship: '',
    current_location: '',

    
    // Professional Information
    designation: '',
    infracon_id: '',
    years_of_experience: '',
    experience: [{
      title: '',
      employment_type: '',
      company_name: '',
      location: '',
      description: '',
      currently_working: true,
      start_date: startDate,
      end_date: endDate,
      isPresent: true
    }],
    sectors: [],
    skills: [],
    
    // Education
    education: [{
      school: '',
      degree: '',
      study: '',
      grade: '',
      description: '',
      start_date: startDate,
      end_date: endDate,
    }],
    
    // Career Preferences
    availability: '',
    current_salary: '',
    expected_salary: '',
    willing_to_relocate: false,
    preferred_location: [],
  });
  const countries = Country.getAllCountries();
  const [stateCountryList, setStateCountryList] = useState([]);
  const [selectedCurrentLocation, setSelectedCurrentLocation] = useState('');
  const [data,setData] = useState({});
  const [error, setError] = useState(false);
  const [dateError, setDateError] = useState('');
  const [fieldErrors, setFieldErrors] = useState([]);
  const [profilePicture, setProfilePicture] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [resumeAttachment, setResumeAttachment] = useState(null);
  const [headerError, setHeaderError] = useState('');

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}api/my-profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      
      // Set the profile picture in the state
      setProfilePicture(result?.details?.profile_picture || null);
      setResumeAttachment(result?.details?.resume_attachment || null);

      // Ensure there's at least one experience entry
      const experiences = result?.details?.experience?.length > 0 
        ? result.details.experience 
        : [{
          title: '',
          employment_type: '',  
          company_name: '',
          location: '',
          description: '',
          currently_working: true,
          start_date: startDate,
          end_date: endDate,
          isPresent: true
          }];

        const educations = result?.details?.education?.length > 0 
        ? result.details.education 
        : [{
          school: '',
          degree: '',
          study: '',
          grade: '',
          description: '',
          start_date: startDate,
          end_date: endDate,
        }];

      // Update formData with existing profile information
      setFormData({
        // Personal Information
        name: result?.user?.name || '',
        phone: result?.user?.phone || '',
        citizenship: result?.details?.citizenship || '',
        current_location: result?.details?.current_location || '',
        
        // Professional Information
        designation: result?.details?.designation || '',
        infracon_id: result?.details?.infracon_id || '',
        years_of_experience: result?.details?.years_of_experience || '',
        sectors: (result?.details?.sectors || []).filter(option =>
          sectorOptions.map(opt => opt.value).includes(option)
        ) || [],
        skills: result?.details?.skills || [],
        experience: experiences, // Use the experiences array we created above
        
        // Education
        education: educations,
        
        // Career Preferences
        availability: result?.details?.availability || '',
        preferred_location: result?.details?.preferred_location || [],
        current_salary: result?.details?.current_salary || '',
        expected_salary: result?.details?.expected_salary || ''
      });


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCountryStateList = () => {
    if (countryData && countryData.countries) {
        const list = countryData.countries.flatMap((country) =>
            country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
        );
        setStateCountryList(list);
    }
}
const fetchSkillList = async () => {
  try {
      const response = await fetch(`${apiUrl}api/professional/skills`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
          },
      });
      const result = await response.json();
      setSkills(result?.skills || []);
      console.log(result)

  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Validation function
const validateExperience = (index) => {
  const experience = formData.experience[index];
  const errors = {};
  let isValid = true;

  // Title validation
  if (!experience.title.trim()) {
    errors.title = "Title is required";
    isValid = false;
  }

  // Company name validation
  if (!experience.company_name.trim()) {
    errors.company_name = "Company name is required";
    isValid = false;
  }

  // Start date validation
  if (!experience.start_date) {
    errors.start_date = "Start date is required";
    isValid = false;
  }

  // End date validation
  if (!experience.currently_working && !experience.end_date) {
    errors.end_date = "End date is required ";
    isValid = false;
  }

  // Set the errors for the specific experience index
  setFieldErrors(prev => {
    const newErrors = [...prev];
    newErrors[index] = errors; // Set errors for the current index
    return newErrors;
  });

  return isValid;
};

const validateEducation = (index) => {
  const education = formData.education[index];
  const errors = {};
  let isValid = true;

  if (!education.school.trim()) {
    errors.school = "School name is required";
    isValid = false;
  }

  if (!education.degree.trim()) {
    errors.degree = "Degree is required";
    isValid = false;
  }

  if (!education.start_date) {
    errors.start_date = "Start date is required";
    isValid = false;
  }

  if (!education.currently_working && !education.end_date) {
    errors.end_date = "End date is required when not currently studying";
    isValid = false;
  }

  setFieldErrors(prev => {
    const newErrors = [...prev];
    newErrors[index] = { ...newErrors[index], ...errors }; // Set errors for the current index
    return newErrors;
  });

    return isValid;
  };

  const handleSubmit = async () => {
    const formDataToSubmit = new FormData();

    // Append each field from formData directly
    Object.keys(formData).forEach(key => {
      if (key === 'sectors') {
        formDataToSubmit.append(key, JSON.stringify(formData.sectors.map(sector => sector.value)));
      } else if (key === 'experience') {
        // Append experience directly without stringifying
        formDataToSubmit.append(key, JSON.stringify(formData.experience));
      } else if (key === 'education') {
        formDataToSubmit.append(key, JSON.stringify(formData.education));
      } else if (key === 'skills') {
        formDataToSubmit.append(key, JSON.stringify(formData.skills));
      } 
      else if (Array.isArray(formData[key])) {
        // If the field is another array, convert it to a JSON string
        formDataToSubmit.append(key, JSON.stringify(formData[key]));
      } else {
        // Append the field directly
        formDataToSubmit.append(key, formData[key]);
      }
    });

    // Append files if they exist
    if (profilePicture) {
      formDataToSubmit.append('profile_picture', profilePicture);
    }
    if (resumeAttachment) {
      formDataToSubmit.append('resume_attachment', resumeAttachment);
    }

    // Log the payload for debugging
    console.log('Payload being sent:', formDataToSubmit);

    try {
      const response = await axios.post(`${apiUrl}api/professional-profile-complete`, formDataToSubmit, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        // Handle success (e.g., navigate to another page or show a success message)
        toast.success('Profile completed successfully!');
        navigate('/profile');
      }
    } catch (error) {
      console.error('Error submitting profile:', error);
      toast.error('An error occurred while submitting your profile. Please try again.');
    }
  };

  const nextStep = () => {
    if (step === 1) {
      if (validatePersonalInfo()) {
        setStep(prev => prev + 1);
      }
    } else if (step === 2) {
      if (validateProfessionalInfo()) {
        setStep(prev => prev + 1);
      }
    } else {
      setStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="form-step">
            <h2 className='text-center'>Personal Information</h2>
            <div className="form-group mt-4 d-flex flex-column align-items-center">
              <div className="profile-image-holder" style={{ position: 'relative', display: 'inline-block' }}>
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Profile Preview"
                    style={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      border: '2px solid #f7931e',
                    }}
                  />
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    style={{
                      width: '100px',
                      height: '100px',
                      backgroundColor: '#6c757d',
                      color: '#fff',
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}
                  >
                    {formData?.name?.charAt(0)}
                  </div>
                )}
                <label htmlFor="fileInput" style={{ cursor: 'pointer', position: 'absolute', bottom: '0', right: '0', borderRadius: '50%', padding: '5px' }}>
                  <span className="material-symbols-outlined" style={{ color: '#fff' }}> add_photo_alternate </span>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Validate file size (1 MB)
                      if (file.size > 1024 * 1024) {
                        toast.error("File size must be less than 1 MB.");
                        setProfilePicture(null);
                        return;
                      }

                      // Validate file type
                      const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
                      if (!validTypes.includes(file.type)) {
                        toast.error("Only JPG, JPEG, PNG, and WEBP formats are allowed.");
                        setProfilePicture(null);
                        return;
                      }

                      setProfilePicture(file);
                      setImagePreview(URL.createObjectURL(file)); // Set image preview
                    }
                  }}
                />
              </div>
              {imagePreview && (
                <button
                  className="btn btn-link text-danger mt-2"
                  onClick={() => {
                    setProfilePicture(null);
                    setImagePreview(null); // Clear the preview
                  }}
                >
                  Remove Picture
                </button>
              )}
            </div>
            <div className="form-group mt-2">
              <label className="form-label">Full Name <span className="text-danger">*</span></label>
              <input
                type="text"
                name="name"
                value={formData?.name}
                onChange={handleInputChange}
                placeholder="Enter your first name"
                className="form-control border border-1 border-gray-300"
              />
            </div>
            
            <div className="form-group">
              <label className="form-label">Phone Number <span className="text-danger">*</span></label>
              <input
                type="tel"
                name="phone"
                value={formData?.phone}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
                className="form-control border border-1 border-gray-300"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Citizenship <span className="text-danger">*</span></label>
               <select
                name="citizenship"
                value={formData?.citizenship}
                onChange={handleInputChange}
                className="form-control border border-1 border-gray-300"
              >
                {countries.map((country, index) => (
                  <option value={country.name} key={index}>{country.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">Current Location <span className="text-danger">*</span></label>
              <Autocomplete
                    options={stateCountryList}
                    freeSolo
                    className={`mt-2 `}
                    value={formData.current_location || ''}
                    required
                    onChange={(event, newValue) => {
                        setFormData(prev => ({
                            ...prev,
                            current_location: newValue
                        }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Current Location" />}
                />
            </div>
            
            <button onClick={nextStep} className="btn btn-primary">Next</button>
          </div>
        );

      case 2:
        return (
          <div className="form-step">
            <h2 className='text-center mb-5'>Professional Information</h2>
            <div className="form-group">
              <label className="form-label">Current Designation</label>
              <input
                type="text"
                name="designation"
                value={formData?.designation}
                onChange={handleInputChange}
                placeholder="Enter your current job title"
                className="form-control border border-1 border-gray-300"
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="yearsOfExperience" className="form-label">Years of Experience <span className="text-danger">*</span></label>
              <input
                type="number"
                className="form-control"  
                id="yearsOfExperience"
                name="years_of_experience"
                value={formData.years_of_experience}
                onChange={handleInputChange}
                placeholder="Enter years of experience"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Infracon ID</label>
              <input
                type="text"
                name="infracon_id"
                value={formData.infracon_id}
                onChange={handleInputChange}
                placeholder="Enter your Infracon ID"
                className="form-control border border-1 border-gray-300"
              />
            </div>

            {/* CV Upload Section */}
            <div className="form-group mt-3">
              <label htmlFor="resume_attachment" className="form-label">Upload Your Updated CV</label>
              <input
                type="file"
                className="form-control"
                id="resume_attachment"
                accept=".pdf,.doc,.docx"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    // Validate file type
                    if (file.size > 1024 * 1024) {
                      toast.error("File size must be less than 1 MB.");
                      setResumeAttachment(null);
                      return;
                    }
                    const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
                    if (!validTypes.includes(file.type)) {
                      toast.error("Only PDF, DOC, and DOCX formats are allowed.");
                      setResumeAttachment(null);
                      return;
                    }

                    // Handle the file upload (you can set it in state or directly upload it)
                    setResumeAttachment(file); // Assuming you have a state variable for the resume
                  }
                }}
                name="resume_attachment"
              />
            </div>

            <div className='form-group mt-4 mb-4'>
                  <label htmlFor="sector">Sector <span className="text-danger">*</span></label>
                  <MultiSelect
                      options={sectorOptions}
                      value={formData.sectors}
                      name="sectors"
                      onChange={(e) => {
                        setFormData(prev => ({
                          ...prev,
                          sectors: e
                        }));
                      }}
                      labelledBy="Select"                    
                  />

              </div>

            <div className='form-group mt-4 mb-4'>
              <label className="form-label">Skills (Please use enter key to add skills)</label>
              <Autocomplete
                    multiple
                    options={skills}
                    freeSolo
                    className="flex-grow-1 bg-white"
                    value={formData?.skills || []}
                    
                    onChange={(event, newValue) => {
                      setFormData(prev => ({
                        ...prev,
                        skills: newValue
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="" />}
                />
            </div>

            {/* Experience Section */}
            <div className="form-group mt-4">
              <label className="form-label">Experience</label>
              
              {formData.experience.map((exp, index) => (
                <div key={index} className="experience-item border rounded p-3 mt-3">
                  {formData.experience.length > 1 && (
                    <div className="d-flex justify-content-end mb-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => {
                          setFormData(prev => ({
                            ...prev,
                            experience: prev.experience.filter((_, i) => i !== index)
                          }));
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Job Title <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className={`form-control ${fieldErrors[index]?.title ? 'is-invalid' : ''}`}
                        placeholder="Ex: Software Engineer"
                        value={exp.title}
                        onChange={(e) => {
                          const updatedExperience = [...formData.experience];
                          updatedExperience[index].title = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            experience: updatedExperience
                          }));
                          if (fieldErrors[index]?.title) {
                            setFieldErrors(prev => {
                              const newErrors = [...prev];
                              newErrors[index] = { ...newErrors[index], title: '' };
                              return newErrors;
                            });
                          }
                        }}
                      />
                      {fieldErrors[index]?.title && (
                        <div className="validate-experience">{fieldErrors[index].title}</div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Company Name <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className={`form-control ${fieldErrors[index]?.company_name ? 'is-invalid' : ''}`}
                        placeholder="Ex: Google"
                        value={exp.company_name}
                        onChange={(e) => {
                          const updatedExperience = [...formData.experience];
                          updatedExperience[index].company_name = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            experience: updatedExperience
                          }));
                          if (fieldErrors[index]?.company_name) {
                            setFieldErrors(prev => {
                              const newErrors = [...prev];
                              newErrors[index] = { ...newErrors[index], company_name: '' };
                              return newErrors;
                            });
                          }
                        }}
                      />
                      {fieldErrors[index]?.company_name && (
                        <div className="validate-experience">{fieldErrors[index].company_name}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Employment Type</label>
                <select
                  className="form-control"
                  value={exp.employment_type}
                  onChange={(e) => {
                    const updatedExperience = [...formData.experience];
                    updatedExperience[index].employment_type = e.target.value;
                    setFormData(prev => ({
                      ...prev,
                      experience: updatedExperience
                    }));
                  }}
                >
                  <option value="">Select Employment Type</option>
                  <option value="Full-time">Full-time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Internship">Internship</option>
                  <option value="Trainee">Trainee</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex: New York, USA"
                  value={exp.location}
                  onChange={(e) => {
                    const updatedExperience = [...formData.experience];
                    updatedExperience[index].location = e.target.value;
                    setFormData(prev => ({
                      ...prev,
                      experience: updatedExperience
                    }));
                  }}
                />
              </div>
            </div>
                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={exp.currently_working}
                      onChange={(e) => {
                        const updatedExperience = [...formData.experience];
                        updatedExperience[index].currently_working = e.target.checked;
                        if (e.target.checked) {
                          updatedExperience[index].end_date = 'Present';
                        } else {
                          updatedExperience[index].end_date = '';
                        }
                        setFormData(prev => ({
                          ...prev,
                          experience: updatedExperience
                        }));
                      }}
                    />
                    <label className="form-check-label">
                    I am currently working in this role

                    </label>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Start Date <span className="text-danger">*</span></label>
                      <input
                        type="month"
                        className={`form-control ${fieldErrors[index]?.start_date ? 'is-invalid' : ''}`}
                        value={exp.start_date}
                        onChange={(e) => {
                          const updatedExperience = [...formData.experience];
                          updatedExperience[index].start_date = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            experience: updatedExperience
                          }));
                          if (fieldErrors[index]?.start_date) {
                            setFieldErrors(prev => {
                              const newErrors = [...prev];
                              newErrors[index] = { ...newErrors[index], start_date: '' };
                              return newErrors;
                            });
                          }
                        }}
                      />
                      {fieldErrors[index]?.start_date && (
                        <div className="validate-experience">{fieldErrors[index].start_date}</div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">End Date {!exp.currently_working && <span className="text-danger">*</span>}</label>
                      <input
                        type={exp.currently_working ? 'text' : 'month'}
                        className={`form-control ${fieldErrors[index]?.end_date ? 'is-invalid' : ''}`}
                        value={exp.currently_working ? 'Present' : exp.end_date}
                        disabled={exp.currently_working}
                        onChange={(e) => {
                          const updatedExperience = [...formData.experience];
                          updatedExperience[index].end_date = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            experience: updatedExperience
                          }));
                          if (fieldErrors[index]?.end_date) {
                            setFieldErrors(prev => {
                              const newErrors = [...prev];
                              newErrors[index] = { ...newErrors[index], end_date: '' };
                              return newErrors;
                            });
                          }
                        }}
                      />
                      {fieldErrors[index]?.end_date && (
                        <div className="validate-experience">{fieldErrors[index].end_date}</div>
                      )}
                    </div>
                  </div>

                 

                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Describe your role and responsibilities"
                      value={exp.description || ''}
                      onChange={(e) => {
                        const updatedExperience = [...formData.experience];
                        updatedExperience[index].description = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          experience: updatedExperience
                        }));
                      }}
                      rows="4"
                    />

                  </div>
                </div>
              ))}

              {/* Add More Experience Button */}
              <div className="text-end mt-3">
                <button 
                  type="button" 
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    // If validation passes, add new experience
                    const newExperience = {
                      title: '',
                      employment_type: '',  
                      company_name: '',
                      location: '',
                      description: '',
                      currently_working: true,
                      start_date: startDate,
                      end_date: 'Present', // Set default end date to "Present"
                      isPresent: true
                    };

                    // setFormData(prev => {
                    //   console.log('Previous formData:', prev); // Debug log
                    //   return {
                    //     ...prev,
                    //     experience: Array.isArray(prev.experience) ? [...prev.experience, newExperience] : [newExperience] // Ensure experience is an array
                    //   };
                    // });

                    setFormData(prev => ({
                      ...prev,
                      experience: [...prev.experience, newExperience]
                    }));

                  }}
                >
                  + Add More Experience
                </button>
              </div>
            </div>
            
            <div className="mt-4">
              <button onClick={prevStep} className="btn btn-secondary me-2">Previous</button>
              <button onClick={nextStep} className="btn btn-primary">Next</button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="form-step">
            <h2 className='text-center mb-5'>Education</h2>

            {formData.education.map((edu, index) => (
              <div key={index} className="education-item border rounded p-3 mt-3">
                {formData.education.length > 1 && (
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          education: prev.education.filter((_, i) => i !== index)
                        }));
                      }}
                    >
                      Remove
                    </button>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">School <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${fieldErrors[index]?.school ? 'is-invalid' : ''}`}
                      placeholder="Ex: Harvard University"
                      value={edu.school}
                      onChange={(e) => {
                        const updatedEducation = [...formData.education];
                        updatedEducation[index].school = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          education: updatedEducation
                        }));
                        if (fieldErrors[index]?.school) {
                          setFieldErrors(prev => {
                            const newErrors = [...prev];
                            newErrors[index] = { ...newErrors[index], school: '' };
                            return newErrors;
                          });
                        }
                      }}
                    />
                    {fieldErrors[index]?.school && (
                      <div className="invalid-feedback">{fieldErrors[index].school}</div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">Degree </label>
                    <input
                      type="text"
                      className='form-control'
                      placeholder="Ex: Bachelor of Science"
                      value={edu.degree}
                      onChange={(e) => {
                        const updatedEducation = [...formData.education];
                        updatedEducation[index].degree = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          education: updatedEducation
                        }));
                        
                      }}
                    />
                    
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Field of Study </label>
                    <input
                      type="text"
                      className='form-control'
                      placeholder="Ex: Computer Science"
                      value={edu.study}
                      onChange={(e) => {
                        const updatedEducation = [...formData.education];
                        updatedEducation[index].study = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          education: updatedEducation
                        }));
                      }}
                    />
                    
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">Grade </label>
                    <input
                      type="text"
                      className='form-control'
                      placeholder="Ex: A"
                      value={edu.grade}
                      onChange={(e) => {
                        const updatedEducation = [...formData.education];
                        updatedEducation[index].grade = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          education: updatedEducation
                        }));
                      }}
                    />
                    
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Start Date <span className="text-danger">*</span></label>
                    <input
                      type="month"
                      className={`form-control ${fieldErrors[index]?.start_date ? 'is-invalid' : ''}`}
                      value={edu.start_date}
                      onChange={(e) => {
                        const updatedEducation = [...formData.education];
                        updatedEducation[index].start_date = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          education: updatedEducation
                        }));
                        if (fieldErrors[index]?.start_date) {
                          setFieldErrors(prev => {
                            const newErrors = [...prev];
                            newErrors[index] = { ...newErrors[index], start_date: '' };
                            return newErrors;
                          });
                        }
                      }}
                    />
                    {fieldErrors[index]?.start_date && (
                      <div className="invalid-feedback">{fieldErrors[index].start_date}</div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">End Date (or Expected) </label>
                    <input
                      type="month"
                      className='form-control'
                      value={edu.end_date}
                      onChange={(e) => {
                        const updatedEducation = [...formData.education];
                        updatedEducation[index].end_date = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          education: updatedEducation
                        }));
                        
                      }}
                    />
                      
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Describe your studies"
                    value={edu.description || ''}
                    onChange={(e) => {
                      const updatedEducation = [...formData.education];
                      updatedEducation[index].description = e.target.value;
                      setFormData(prev => ({
                        ...prev,
                        education: updatedEducation
                      }));
                    }}
                    rows="4"
                  />
                </div>
              </div>
            ))}

            {/* Add More Education Button */}
            <div className="text-end mt-3">
              <button 
                type="button" 
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  const newEducation = {
                    school: '',
                    degree: '',
                    study: '',
                    grade: '',
                    description: '',
                    start_date: startDate,
                    end_date: endDate, // Default end date can be set to Present or left empty
                  };
                  setFormData(prev => ({
                    ...prev,
                    education: [...prev.education, newEducation]
                  }));

                }}
              >
                + Add More Education
              </button>
            </div>

            <div className="mt-4">
              <button onClick={prevStep} className="btn btn-secondary me-2">Previous</button>
              <button onClick={nextStep} className="btn btn-primary">Next</button>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="form-step">
            <h2>Career Preferences</h2>
            <div className="form-group">
              <label className="form-label">Availability</label>
              <select
                name="availability"
                value={formData.availability}
                onChange={handleInputChange}
                className="form-control border border-1 border-gray-300"
              >
                <option value="">Select Availability</option>
                <option value="immediately">Immediately</option>
                <option value="proposal">Proposal</option>
                <option value="any_opportunity">Any Opportunity</option>
                <option value="not_available">Not Available</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">Current Salary</label>
              <input
                type="number"
                name="current_salary"
                value={formData.current_salary}
                onChange={handleInputChange}
                placeholder="Enter current salary"
                className="form-control border border-1 border-gray-300"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Expected Salary</label>
              <input
                type="number"
                name="expected_salary"
                value={formData.expected_salary}
                onChange={handleInputChange}
                placeholder="Enter expected salary"
                className="form-control border border-1 border-gray-300"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Preferred Location</label>
              <Autocomplete
                    multiple // Allows multiple selections
                    options={stateCountryList}
                    freeSolo
                    className={`mt-2`}
                    value={formData?.preferred_location || []}
                    required
                    onChange={(event, newValue) => handleInputChange({
                      target: {
                        name: 'preferred_location',
                        value: newValue
                      }
                    })}                    
                    renderInput={(params) => <TextField {...params} label="" />}
                />
            </div>
           
            <button onClick={prevStep} className="btn btn-secondary me-2">Previous</button>
            <button onClick={handleSubmit} className="btn btn-success">Complete Profile</button>
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
      fetchCountryStateList();
      fetchSkillList();
    }
}, [token]);

  const validatePersonalInfo = () => {
    const errors = {};
    let isValid = true;

    // Check required fields
    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
      isValid = false;
    }
    if (!formData.citizenship.trim()) {
      errors.citizenship = "Citizenship is required";
      isValid = false;
    }
    if (!formData.current_location.trim()) {
      errors.current_location = "Current location is required";
      isValid = false;
    }

    setFieldErrors(prev => ({
      ...prev,
      personalInfo: errors
    }));

    if (!isValid) {
      setHeaderError('Please fill all required fields.');
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top

    } else {
      setHeaderError(''); // Clear the error if valid
    }

    return isValid;
  };

  const validateProfessionalInfo = () => {
    const errors = {};
    let isValid = true;

    // Check required fields
    if (!formData.years_of_experience) {
      errors.years_of_experience = "Years of experience is required";
      isValid = false;
    }
    if (formData.sectors.length === 0) {
      errors.sectors = "At least one sector must be selected";
      isValid = false;
    }

    setFieldErrors(prev => ({
      ...prev,
      professionalInfo: errors
    }));

    if (!isValid) {
      setHeaderError('Please fill all required fields.');
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top

    } else {
      setHeaderError(''); // Clear the error if valid
    }

    return isValid;
  };

  return (
    <>
    <BasicLayout>
      <ToastContainer />
      <div className='inner-banner'>
          <div className='container d-flex justify-content-between align-items-center'>
              <h2>Complete Your Profile</h2>
          </div>
      </div>
      <div className="profile-completion-container">
        <div className="stepper-wrapper">
          <div className={`stepper-item ${step >= 1 ? 'completed' : ''}`}>
            <div className="step-counter">1</div>
            <div className="step-name">Personal</div>
          </div>
          <div className={`stepper-item ${step >= 2 ? 'completed' : ''}`}>
            <div className="step-counter">2</div>
            <div className="step-name">Professional</div>
          </div>
          <div className={`stepper-item ${step >= 3 ? 'completed' : ''}`}>
            <div className="step-counter">3</div>
            <div className="step-name">Education</div>
          </div>
          <div className={`stepper-item ${step >= 4 ? 'completed' : ''}`}>
            <div className="step-counter">4</div>
            <div className="step-name">Preferences</div>
          </div>
        </div>
        <div className="form-content">
          {headerError && (
            <div className="alert alert-danger text-center" role="alert">
              {headerError}
            </div>
          )}
          {renderStep()}
        </div>
      </div>
    </BasicLayout>
    </>

  );
};

export default ProfileCompletionForProfessional; 