import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { MultiSelect } from 'react-multi-select-component';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const organizationTypes = [
  { label: "Construction", value: "Construction" },
  { label: "Development Consultant", value: "Development Consultant" },
  { label: "Funding Agency", value: "Funding Agency" },
  { label: "Finance", value: "Finance" },
  { label: "Foundation", value: "Foundation" },
  { label: "Government Organization", value: "Government Organization" },
  { label: "Institute", value: "Institute" },
  { label: "Manufacturer", value: "Manufacturer" },
  { label: "Service Provider", value: "Service Provider" },
  { label: "Goods Supplier", value: "Goods Supplier" },
  { label: "Works", value: "Works" },
  { label: "Other", value: "Other" }

];
const currencyCodes = [
  "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD", "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "INR", "RUB", "BRL", "ZAR", "PHP", "PLN", "THB", "MYR", "IDR", "HUF", "CZK", "ILS", "CLP", "PKR", "BDT", "NPR"
];
const ProfileCompletionForOrg = () => {
  const navigate = useNavigate();
  const { token } = useSelector(selectAuth);
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const [headerError, setHeaderError] = useState('');
  const [fieldErrors, setFieldErrors] = useState([]);
  const [formData, setFormData] = useState({
    company_name: '',
    website_link: '',
    organization_type: '',
    operational_countries: [],
    year_of_incorporation: '',
    incorporation_date: '',
    turnover: '',
    about: '',
    headquarter: '',
    address: ''
  });
  const [logo, setLogo] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [step, setStep] = useState(1); // Step state
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const today = new Date().toISOString().split('T')[0];

  const handleSelectCurrency = (code) => {
    setSelectedCurrency(code);
};
  const fetchData = async () => {
    try {
        const response = await fetch(`${apiUrl}api/my-profile`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = await response.json();
        console.log(result)
        if (result && result?.company) {
            setFormData({
                company_name: result?.company.company_name,
                organization_type: result?.company.organization_type,
                website_link: result?.company.website_link,
                operational_countries: [],
                location: result?.company.location,
                year_of_incorporation: result?.company.year_of_incorporation,
                incorporation_date: result?.company.incorporation_date,
                turnover: result?.company.turnover,
                about: result?.company?.about || '',
                user: result.user,
                headquarter: result?.company.headquarter,
                address: result?.company.address
                
            });
            setSelectedCurrency(result?.company.currency)
            setLogo(result?.company.logo);
        }

        if (result?.company?.operational_countries) {
            const parsedOperationalCountries = JSON.parse(result.company.operational_countries);
            const initialSelected = parsedOperationalCountries.map(country => ({
                label: country,
                value: country
            }));
            setSelected(initialSelected);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await fetch(`${apiUrl}api/countries`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (Array.isArray(result)) {
        setOptions(result.map(country => ({ label: country.name, value: country.name })));
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCompanyInfoSubmit = async (e) => {
    e.preventDefault();
    try {
        const newData = new FormData(); // Create a FormData object

        // Append all the necessary fields to the FormData object
        newData.append('company_name', formData.company_name);
        newData.append('website_link', formData.website_link);
        newData.append('organization_type', formData.organization_type);
        newData.append('operational_countries', JSON.stringify(selected.map(country => country.value))); // Ensure this is sent as a string
        newData.append('year_of_incorporation', formData.year_of_incorporation);
        newData.append('incorporation_date', formData.incorporation_date);
        newData.append('turnover', formData.turnover);
        newData.append('about', formData.about);
        newData.append('headquarter', formData.headquarter);
        newData.append('address', formData.address);
        newData.append('currency', selectedCurrency);
        
        // Append the logo file if it exists
        if (logo) {
            newData.append('logo', logo); // Append the logo file
        }

        const response = await axios.post(`${apiUrl}api/organization-profile-complete`, newData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });

        toast.success("Company Info saved successfully !!", {
            className: "toast-message",
        });
        navigate('/my-profile');
    } catch (error) {
        console.error('Error updating profile:', error);
    }
  };
  const validateInfo = () => {
    const errors = {};
    let isValid = true;

    // Check required fields
    if (!formData.company_name || !formData.company_name.trim()) {
        errors.company_name = "Company Name is required";
        isValid = false;
    }
    if (!formData.website_link || !formData.website_link.trim()) {
        errors.website_link = "Website Link is required";
        isValid = false;
    }
    if (!formData.organization_type) {
        errors.organization_type = "Organization Type is required";
        isValid = false;
    }
    if (!formData.operational_countries) {
        errors.operational_countries = "Operational Countries is required";
        isValid = false;
    }
    if (!formData.headquarter || !formData.headquarter.trim()) {
        errors.headquarter = "Headquarter is required";
        isValid = false;
    }

    setFieldErrors(prev => ({
        ...prev,
        personalInfo: errors
    }));

    if (!isValid) {
      console.log('error', errors)
      console.log('formData', formData)
        setHeaderError('Please fill all required fields.');
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
    } else {
        setHeaderError(''); // Clear the error if valid
    }

    return isValid;
  };
  const nextStep = () => {
    if(step === 1){
      if(validateInfo()){
        setStep(prev => prev + 1);
      }
    }
    else {
        setStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    setStep(prev => prev - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="form-step">
            <h2 className='text-center'>Organization Information</h2>
            <div className="form-group mt-4 d-flex flex-column align-items-center">
              <div className="profile-image-holder" style={{ position: 'relative', display: 'inline-block' }}>
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Logo Preview"
                    style={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      border: '2px solid #f7931e',
                    }}
                  />
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    style={{
                      width: '100px',
                      height: '100px',
                      backgroundColor: '#6c757d',
                      color: '#fff',
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}
                  >
                    {formData?.company_name?.charAt(0)}
                  </div>
                )}
                <label htmlFor="fileInput" style={{ cursor: 'pointer', position: 'absolute', bottom: '0', right: '0', borderRadius: '50%', padding: '5px' }}>
                  <span className="material-symbols-outlined" style={{ color: '#fff' }}> add_photo_alternate </span>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Validate file size (1 MB)
                      if (file.size > 1024 * 1024) {
                        toast.error("File size must be less than 1 MB.");
                        setLogo(null);
                        return;
                      }

                      // Validate file type
                      const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
                      if (!validTypes.includes(file.type)) {
                        toast.error("Only JPG, JPEG, PNG, and WEBP formats are allowed.");
                        setLogo(null);
                        return;
                      }

                      setLogo(file);
                      setImagePreview(URL.createObjectURL(file)); // Set image preview
                    }
                  }}
                />
              </div>
              {imagePreview && (
                <button
                  className="btn btn-link text-danger mt-2"
                  onClick={() => {
                    setLogo(null);
                    setImagePreview(null); // Clear the preview
                  }}
                >
                  Remove Picture
                </button>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="company_name">Organization Name <span className='text-danger'>*</span></label>
              <input type="text" className="form-control" id="company_name" name="company_name" value={formData.company_name} required onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="organization_type">Organization Type <span className='text-danger'>*</span></label>
              <select className="form-control" id="organization_type" name="organization_type" value={formData.organization_type} required onChange={handleInputChange}>
              <option value="">Select Type </option>
              {organizationTypes?.map((type, index) => (
                  <option key={index} value={type.value}>{type.label}</option>
              ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="website_link">Website <span className='text-danger'>*</span></label>
              <input type="url" className="form-control" id="website_link" name="website_link" value={formData.website_link} required onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="headquarter">Headquarter <span className='text-danger'>*</span></label>
              <input type="text" className="form-control" id="headquarter" name="headquarter" value={formData.headquarter} required onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="operational_countries">Operational Countries <span className='text-danger'>*</span></label>
              <MultiSelect
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                name="operational_countries"
                required
              />
            </div>
            <button onClick={nextStep} className="btn btn-primary">Next</button>
          </div>
        );
      case 2:
        return (
          <div className="form-step">
            <h2 className='text-center'>Additional Information</h2>
            <div className="form-group">
              <label htmlFor="address">Office Address</label>
              <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleInputChange} />
            </div>
            <div className="form-group">
                                            <label htmlFor="turnover">Turnover (in Million)</label>

                                            <div class="input-group mt-2">
                                                <input type="number" className="form-control" id="turnover" name="turnover" value={formData?.turnover} min={0} onChange={handleInputChange} />
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn btn-currency dropdown-toggle"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {selectedCurrency || 'INR'}
                                                    </button>
                                                    <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                        {currencyCodes.map((code, index) => (
                                                            <a
                                                                className="dropdown-item"
                                                                key={index}
                                                                onClick={() => handleSelectCurrency(code)}
                                                            >
                                                                {code}
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

            {/* Year of Incorporation */}
            <div className="form-group">
              <label htmlFor="year_of_incorporation">Year of Incorporation</label>
              <input type="text" className="form-control mt-2" id="year_of_incorporation" name="year_of_incorporation" value={formData?.year_of_incorporation} onChange={handleInputChange} />
            </div>
            {/* Incorporation Date */}
            <div className="form-group">
              <label htmlFor="incorporation_date">Incorporation Date</label>
              <input type="date" className="form-control" id="incorporation_date" name="incorporation_date" value={formData.incorporation_date} max={today} onChange={handleInputChange} />
            </div>
            
            <div className="form-group">
              <label htmlFor="about">About </label>
              <CKEditor
                  editor={ClassicEditor}
                  data={formData.about || ''} // Provide a fallback empty string
                  onChange={(event, editor) => {
                      const data = editor.getData();
                      setFormData(formData => ({
                          ...formData,
                          about: data
                      }));
                  }}
                  
              />
            </div>
            <div className="text-center">
              <button onClick={prevStep} className="btn btn-secondary me-2">Previous</button>
              <button onClick={handleCompanyInfoSubmit} className="btn btn-success">Save Changes</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchData();
    fetchCountries()
}, [token]);

  return (
    <>
      <BasicLayout>
        <ToastContainer />
        <div className='inner-banner'>
          <div className='container d-flex justify-content-between align-items-center'>
            <h2>Complete Your Organization Profile</h2>
          </div>
        </div>
        {/* <div className="profile-completion-container">
          {renderStep()}
        </div> */}
        <div className="profile-completion-container">
        <div className="stepper-wrapper">
          <div className={`stepper-item ${step >= 1 ? 'completed' : ''}`}>
            <div className="step-counter">1</div>
            <div className="step-name">Organization Information</div>
          </div>
          <div className={`stepper-item ${step >= 2 ? 'completed' : ''}`}>
            <div className="step-counter">2</div>
            <div className="step-name">Additional Information
            </div>
          </div>
        </div>
        <div className="form-content">
          {headerError && (
            <div className="alert alert-danger text-center" role="alert">
              {headerError}
            </div>
          )}
          {renderStep()}
        </div>
      </div>
      </BasicLayout>
    </>
  );
};

export default ProfileCompletionForOrg;