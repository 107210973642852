import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import ProfileCompletionForProfessional from './ProfileCompletionForProfessional';
import ProfileCompletionForOrg from './ProfileCompletionForOrg';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const { user } = useSelector(selectAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.user.role_id === 4 && user.user.profile_completed === 1) {
        navigate('/profile');
      } else if (user.user.role_id === 3 && user.user.profile_completed === 1) {
        navigate('/my-profile'); 
      }
    }
  }, [user, navigate]);

  return (
    <>
      {user?.user?.role_id === 4 && user?.user?.profile_completed === 0 ? (
        <ProfileCompletionForProfessional />
      ) : (
        <>
          {user?.user?.role_id === 3 && user?.user?.profile_completed === 0 ? (
            <ProfileCompletionForOrg />
          ) : (
            <div>
              <h1>You are not authorized to access this page</h1>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Index;